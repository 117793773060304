.container {
    width: 100%;
}

@media (min-width: 640px) {
    .container {
        max-width: 640px;
    }
}

@media (min-width: 768px) {
    .container {
        max-width: 768px;
    }
}

@media (min-width: 1024px) {
    .container {
        max-width: 1024px;
    }
}

@media (min-width: 1280px) {
    .container {
        max-width: 1280px;
    }
}

@media (min-width: 1536px) {
    .container {
        max-width: 1536px;
    }
}

.banner .bannerCarouseltem {
    width: 100%;
    background: #f7f7f7;
    overflow: hidden;
    margin-top: 120px;
}

.banner .bannerCarouseltem .bannerCarousel {
    width: 100%;
    background: #f7f7f7;
    overflow: hidden;
    display: flex;
    justify-content: center;
}

.banner .bannerCarouseltem .bannerCarousel img {
    min-width: 100%;
    height: 100%;
    margin: 0 auto;
}

.features .featuresList {
    margin: 10px 10px;
    padding: 12px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    background: white !important;
    box-shadow: 0px 0px 10px #ccc;
    border-radius: 10px;
}

.featuresmain {
    background-color: white;
}

.features .owl-nav {
    display: none;
}

.features .featuresList .imgBox {
    width: 50px;
    height: 50px;
    margin-bottom: 6px;
}

.features .featuresList .imgBox img {
    width: 100%;
}

.features .featuresList h4 {
    font-size: 14px;
    font-weight: 600;
    margin: 0;
    margin-bottom: 5px;
}

.features .featuresList label {
    font-size: 11px;
    text-align: center;
    display: flex;
    min-height: 36px;
    align-items: center;
    justify-content: center;
}

.owl-theme .owl-dots {
    margin-top: 12px;
}


.column {
    margin: 15px 15px 0;
    padding: 0;
    display: flex;
    justify-content: center;
}

.column:last-child {
    padding-bottom: 60px;
}

.column::after {
    content: '';
    clear: both;
    display: block;
}

.column div {
    position: relative;
    float: left;
    width: 300px;
    height: 200px;
    margin: 0 0 0 25px;
    padding: 0;
}

.column div:first-child {
    margin-left: 0;
}

.column div span {
    position: absolute;
    bottom: -20px;
    left: 0;
    z-index: -1;
    display: block;
    width: 300px;
    margin: 0;
    padding: 0;
    color: black;
    font-size: 18px;
    text-decoration: none;
    text-align: center;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
    opacity: 0;
}

figure {
    width: 300px;
    height: 200px;
    margin: 0;
    padding: 0;
    background: #fff;
    overflow: hidden;
}

figure:hover+span {
    bottom: -36px;
    opacity: 1;
}

/* Zoom In #1 */
.hover01 figure img {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
}

.hover01 figure:hover img {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
}

@media screen and (max-width: 430px) {
    .features .featuresList {
        width: 55px;
        padding: 5px 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        background: #fff !important;
        box-shadow: 0px 0px 10px #ccc;
        border-radius: 10px;
    }

    .featuresmain {
        background-color: white;
    }

    .features .owl-nav {
        display: none;
    }

    .features .featuresList .imgBox {
        width: 50px;
        height: 25px;
    }

    .features .featuresList .imgBox img {
        width: 50%;
        margin-left: 15px;
    }

    .features .featuresList h4 {
        font-size: 6px;
        font-weight: 600;

    }

    .features .featuresList label {
        font-size: 6px;
        text-align: center;
        display: flex;
        min-height: 36px;
        align-items: center;
        justify-content: center;
    }
}