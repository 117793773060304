.cart-view {
    max-height: 78vh;
    overflow-y: scroll;
    overflow-x: visible;
    position: relative
}

.cart-view .cart-item {
    padding: 24px;
    border-bottom: 1px solid #f0f0f0;
    display: flex;
    justify-content: space-between;
    gap: 30px
}

.cart-item .image-view {
    padding: 0;
    width: 20%;
}

.cart-item .image-view .img-qty {
    background-color: white;
    width: 100%;
    border-radius: 8px;
}

.cart-item .image-view .img-qty .img-box {
    border: 1px solid rgb(234, 234, 242);
    height: 170px;
    border-radius: 4px;
    position: relative;
}

.cart-item .image-view .img-qty .img-box img {
    position: absolute;
    height: 100%;
    width: 100%;
    inset: 0px;
    object-fit: contain;
    color: transparent;
}

.img-qty .qty-update {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-top: 24px
}

.img-qty .qty-update .minus {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #c2c2c2;
    background: linear-gradient(#fff, #f9f9f9);
    border-radius: 50%;
    font-size: 30px;
    width: 58px;
    height: 45px;
    padding: 10px;
    cursor: pointer;
}

.img-qty .qty-update input {
    padding: 3px 6px;
    margin: 0 5px;
    width: 94px;
    height: 45px;
    border: 1px solid #c2c2c2
}

.img-qty .qty-update .plus {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #c2c2c2;
    background: linear-gradient(#fff, #f9f9f9);
    border-radius: 50%;
    font-size: 30px;
    width: 58px;
    height: 45px;
    padding: 10px;
    cursor: pointer;
}

.cart-item .item-detail {
    width: 80%;
    margin-left: 40;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.cart-item .item-detail .itemName {
    font-size: 22px;
    color: black;
}

.cart-item .item-detail .item-qty {
    font-size: 16px;
    font-family: sans-serif
}

.cart-item .item-detail .comments {
    font-size: 20px;
    color: black;
    font-weight: 500
}

.cart-item .item-detail .price-list {
    display: flex;
    justify-content: space-between;
    align-items: center
}

.price-list .sell-price {
    font-size: 26px;
    color: black;
    font-weight: 500
}

.price-list .MRP {
    font-size: 22px;
    color: #8b8ba3;
    font-weight: 400;
    margin-left: 10px;
    text-decoration: line-through;
}

.price-list .avail-offer {
    font-size: 17px;
    color: #388e3c;
    font-weight: 600;
    margin-left: 10px;
}

.price-list .remove-bar {
    display: flex;
    font-size: 18px;
    color: black;
    font-weight: 700;
    cursor: pointer;
}