@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');

body {
  margin: 0;
  padding: 0;
  color: #0b385d;
  background-color: #f9f9f9 !important;
  font-family: 'Montserrat', sans-serif !important;
  font-size: 14px;
  font-weight: 600;

}

::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #3333336b;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #333;
}

.full-width {
  width: 100% !important;
}

.width-auto {
  width: auto !important;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.pd-t-0 {
  padding-top: 0 !important;
}

.pd-0-12 {
  padding: 0 12px !important;
}

.mr-l-0 {
  margin-left: 0 !important;
}

.vertical-space-5 {
  display: block;
  width: 100%;
  height: 5px;
}

.vertical-space-10 {
  display: block;
  width: 100%;
  height: 10px;
}

.vertical-space-15 {
  display: block;
  width: 100%;
  height: 15px;
}

.vertical-space-20 {
  display: block;
  width: 100%;
  height: 20px;
}

.vertical-space-30 {
  display: block;
  width: 100%;
  height: 30px;
}

.vertical-space-40 {
  display: block;
  width: 100%;
  height: 40px;
}

.vertical-space-60 {
  display: block;
  width: 100%;
  height: 60px;
}

.vertical-space-90 {
  display: block;
  width: 100%;
  height: 90px;
}

.vertical-space-120 {
  display: block;
  width: 100%;
  height: 120px;
}

.App {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: auto;
  display: flex;
  flex-direction: column;
  background-color: white;
}

.App .header {
  background: #dc3545;
  padding-top: 12px;
  height: fit-content
}

.App .header .menuHeader {
  background: #dc3545;
  margin-top: 12px;
  border-top: 1px solid white;
}

.App .header .menuHeader ul {
  margin: 0;
  display: flex;
  gap: 6px;
}

.App .header .menuHeader ul li a {
  padding: 6px 12px;
  background: transparent;
  color: #fff;
  border-radius: 6px;
  transition-duration: 0.06s;
}

.App .header .menuHeader ul li a:hover {
  background: #ffffff27;
}

.App .header .menuHeader ul li.active a {
  background-color: #fff;
  color: #000;
}

.App .header .navbar {
  padding: 0px 0;
}

.App .header .navbar .navbar-brand {
  height: 72px;
  padding: 0;
  margin-left: 4px;
  background: #fff;
}

.App .header .navbar .navbar-brand img {
  height: 100%;
}

.App .header .navbar .navbar-collapse {
  justify-content: center;
  gap: 20px;
}

.App .header .navbar .navBar.navbar-collapse {
  display: flex !important;
  justify-content: end;
  gap: 20px;
}

.Login {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex-direction: column;
}

.App .header .navbar .navbar-collapse .LoginBtn {
  width: auto;
  padding: 8px 25px;
  border: 1px solid #fff;
  color: #fff;
  font-weight: 500;
  font-size: '20px';
  border-radius: 8px;
  margin-left: 3px;
  height: 45px;
}

.App .header .navbar .locationInputBox .MuiAutocomplete-root {
  height: 46px;
}

.css-wb57ya-MuiFormControl-root-MuiTextField-root {
  height: 46px;
}

.App .header .navbar .css-1xnbq41-MuiAutocomplete-root .MuiOutlinedInput-root.MuiInputBase-sizeSmall {
  height: 46px;
  background-color: #fff;
  font-family: 'Montserrat', sans-serif !important;
  color: #333;
}

.App .header .navbar .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border: 0px;
}

.App .header .navbar .searchInputBox {
  background: #fff;
  border-radius: 8px;
  display: flex;
  width: 52%;
  z-index: 100;

}

.App .header .navbar .searchInputBox input {
  height: 46px;
  margin: 0;
  border: 0px;
}

.App .header .navbar .searchInputBox button {
  min-width: 46px;
  background: #333;
  color: #fff;
  border: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  font-weight: 600;
  padding: 10px;

}

.App .header .navbar .cartBtn {
  width: 45px;
  height: 45px;
  background: transparent;
  border: 0;
  padding: 5px;
  margin-left: 20px;
}

.App .header .navbar .cartBtn img {
  width: 100%;
}

.carousel-control-prev {
  left: -200px;
  height: 360px;
}

.carousel {
  margin-top: 50px;

}

.carousel-inner {
  width: 100%;
}

.Product-detail {
  background: white;
  height: 100%;
  width: 100%;
  padding: 16px;
}

.TopProduct .TopProductList {
  background: #fff;
  box-shadow: 0px 0px 15px #efefefc9;
  padding: 16px;
  margin-bottom: 20px;
}

.TopProduct .TopProductList h5 {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 23px;
  color: black;
  height: 40px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.TopProduct .TopProductList h5 span {
  float: right;
  font-size: 14px;
  color: black;
}

.TopProduct .TopProductList.ProductsGrid h5 div {
  height: auto;
  font-size: 14px;
  font-weight: 500;
  color: #96979d;
  display: inline;
  text-transform: none;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.TopProduct .TopProductList.ProductsGrid h5 {
  height: auto;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.TopProduct .TopProductList .Category {
  display: flex;
  gap: 6px 12px;
  flex-wrap: wrap;
}

.TopProduct .TopProductList .Category .CategoryList {
  width: calc(50% - 6px);
}

.TopProduct .TopProductList .Category .CategoryList .imgBox {
  width: 100%;
  aspect-ratio: 4/3;
}

.TopProduct .TopProductList .Category .CategoryList .imgBox span .backlabel {
  height: 50px;
  width: 210%;
  margin-top: -60%;
  z-index: -1;
  background-color: #EEEEEE;
  border: none;
}

.TopProduct .TopProductList .Category .CategoryList .imgBox span .viewbtn {
  margin-top: -90%;
  margin-left: 50%;
  background-color: #EF5350;
  border: none;
}

.TopProduct .TopProductList .Category .CategoryList .imgBox span .detailbtn {
  margin-top: -123%;
  margin-left: 110%;
  background-color: white;
  color: black;
  border: 1px solid salmon;
}

.TopProduct .TopProductList .Category .CategoryList .imgBox img {
  width: 120%;
  height: 120%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.TopProduct .TopProductList .Category .CategoryList label {
  font-size: 12px;
  height: 26px;
  line-height: 13px;
  margin-top: 2px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.TopProduct .TopProductList .Products .imgBox {
  width: 100%;
  aspect-ratio: 4/3;
  background-color: #f3f3f3;
  display: flex;
  justify-content: center;
  align-items: center;
}

.TopProduct .TopProductList .Products .imgBox img {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
}

.TopProduct .TopProductList .Products h4 {
  font-size: 14px;
  font-weight: 500;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 5px;
}

.TopProduct .TopProductList .Products h3 {
  color: #0b385d;
  font-size: 20px;
  font-weight: 600;
}

.TopProduct .TopProductList .Products h3 del {
  font-size: 14px;
  color: #ccc;
  font-weight: 500;
}

.TopProduct .TopProductList .Products h3 span {
  font-size: 14px;
  font-weight: 500;
  color: #333;
}

.TopProduct .TopProductList .Products button {
  max-width: 100%;
  /* height: 46px; */
  padding: 8px 20px;
  background: #2d2c2c;
  color: #fff;
  border: 0px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;


}



h3.hr {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 23px;
  color: black
}

.TopBrands .owl-stage-outer {
  padding: 0 30px;
}

.TopBrands .owl-carousel .owl-stage-outer {
  z-index: -1;
}

.TopBrands .owl-carousel .owl-nav {
  position: absolute;
  z-index: -1;
  top: 3px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0;
}

.TopBrands .owl-carousel .owl-nav button {
  width: 30px;
  height: 40px;
  background: #2d2c2c;
  color: #fff;
  font-size: 36px;
  margin: 0;
  z-index: 555;
  line-height: 25px;
  padding-bottom: 5px !important;
  text-align: center;
}

.TopBrands .owl-carousel .owl-nav button span {
  height: 30px;
}

.TopBrands .item .TopBrandsList .imgBox {
  height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 10px;
}

.TopBrands .item .TopBrandsList .imgBox img {
  max-height: 100%;
  max-width: 100%;
  width: auto;
}

.footerBottomReach {
  width: 96%;
  position: fixed;
  bottom: 5%;
  display: flex;
  justify-content: space-around;
}

.bottomDiv {
  width: 80%;
  display: flex;
  background: #dc3545;
  justify-content: space-between;
  color: #fff;
  padding: 16px;
}

.footer {
  background: #dc3545;
  color: #fff;
}

.footer .NewsletteSignup {
  background: #dc3545;
  color: #fff;
  padding: 16px 0;
}

.footer .input-group {
  height: 40px;
}

.footer .input-group button {
  color: #fff;
  padding: 5px 16px;
}

.footer h4 {
  font-size: 14px;
  font-weight: 600;
  margin: 0;
  margin-bottom: 5px;
}

.footer p {
  margin-top: 4px;
  margin-bottom: 0;
  font-size: 12px;
  font-weight: 400;
}

.footer .bottomFooter {
  border-top: 1px solid #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0;
}

.footer .bottomFooter ul {
  margin: 0;
  display: flex;
  gap: 16px;
}

.footer .bottomFooter ul li img {
  height: 20px;
  width: auto;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
  font-size: 12px;
}

.footersearch {
  margin-left: 85px;
}

.outletInputField {
  width: 560px;
  max-width: calc(100% - 30px);
  margin: 0 auto;
  box-shadow: 0px 0px 10px #e7e7e7;
  border-radius: 15px;
  background: #fff;

}

.inputFormBox.LgInputField {
  width: 100%;
}

.inputFormBox .hrBox {
  width: 100%;
  padding: 20px 24px;
  background: #c5c5c5;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.inputFormBox .hrBox h3 {
  margin: 0;
  font-size: 24px;
  font-weight: 600;
  color: #fff;
}

.inputFormBox.LgInputField .ItemInwardInputBox {
  /* width: 560px; */
  margin: 0 auto;
}

.inputFormBox.LgInputField .LgInputListsBox {
  width: 560px;
  margin: 0 auto;
}

.inputFormBox .inputBoxLists {
  padding: 20px 24px;
}

.inputFormBox .inputBoxLists h5 {
  font-size: 16px;
  font-weight: 300;
  color: #b1b1b1;
  margin: 0;
}

.inputFormBox .inputBoxLists .inputBox {
  width: 100%;
}

.inputFormBox .inputBoxLists .inputBox .MuiInputBase-root.MuiOutlinedInput-root {
  height: 46px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.inputFormBox .inputBoxLists .inputBox .MuiInputBase-root.MuiOutlinedInput-root input {
  border: 0;
  font-family: 'Montserrat', sans-serif !important;
}

.inputFormBox .inputBoxLists .inputBox.errorInputBox input,
.inputFormBox .inputBoxLists .inputBox.errorInputBox textarea,
.inputFormBox .inputBoxLists .inputBox.errorInputBox select {
  border: 1px solid #DC3545;
}

.inputFormBox .inputBoxLists .inputBox.errorInputBox p {
  color: #DC3545;
  font-size: 12px;
  font-weight: 600;
  margin-top: 5px;
  margin-bottom: 0px;
}

.inputFormBox .inputBoxLists .inputBox label {
  color: #19394b;
  font-size: 15px;
  font-weight: 600;
  display: block;
}

.inputFormBox .inputBoxLists .inputBox label span {
  color: #DC3545;
}

.inputFormBox .inputBoxLists .inputBox input {
  border: 1px solid #ccc;
  padding: 10px 15px;
  border-radius: 5px;
}

.inputFormBox .inputBoxLists .inputBox textarea {
  border: 1px solid #ccc;
  padding: 10px 20px;
  border-radius: 5px;
  height: 100px;
}

.inputFormBox .inputBoxLists .inputBox select {
  border: 1px solid #ccc;
  padding: 12px 20px;
  border-radius: 5px;
}

.inputFormBox .btnBox {
  display: flex;
  justify-content: right;
  padding: 0px 24px 20px;
}

.viewBtn {
  width: auto;
  height: 36px;
  float: left;
  border: 0px solid #dc3545;
  background: transparent;
  padding: 5px 10px;
  text-decoration: underline;
  color: #dc3545;
  border-radius: 6px;
  margin-right: 20px;
  font-size: 14px;
  font-weight: 600;
  font-family: Montserrat;
}

.viewBtn span {
  text-decoration: underline;
}

.secondaryBtn {
  /* width: 100px; */
  height: 36px;
  float: left;
  border: 2px solid #dc3545;
  background: transparent;
  padding: 5px 10px;
  color: #dc3545;
  border-radius: 6px;
  margin-right: 20px;
  font-size: 14px;
  font-weight: 600;
  font-family: Montserrat;
}

.dfBtn {
  /* width: 100px; */
  height: 36px;
  float: left;
  border: 2px solid #dc3545;
  background-color: #dc3545;
  padding: 5px 17px 0px;
  color: #fff;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 600;
  font-family: Montserrat;
}

.orderbtn {
  /* width: 100px; */
  height: 36px;
  border: 2px solid #dc3545;
  background-color: #dc3545;
  color: #fff;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 600;
  font-family: Montserrat;
}

.usethisbtn {
  height: 36px;
  border: 2px solid #dc3545;
  background-color: #dc3545;
  color: #fff;
  border-radius: 6px;
  font-size: 12px;
  font-weight: 600;
  font-family: Montserrat;
}

.placeorderbtn {
  height: 36px;
  border: 2px solid #dc3545;
  background-color: #dc3545;
  color: #fff;
  border-radius: 6px;
  font-size: 12px;
  font-weight: 600;
  font-family: Montserrat;
  margin-top: 5%;
  margin-left: 4%;
}


.verifybtn {
  height: 36px;
  float: left;
  border: 2px solid #dc3545;
  background: transparent;
  padding: 5px 20px;
  color: #dc3545;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 600;
  font-family: Montserrat;
  margin-top: -53px;
}

.verifybtn1 {
  height: 36px;
  max-width: 91%;
  float: left;
  border: 2px solid #dc3545;
  background: transparent;
  padding: 5px 20px;
  color: #dc3545;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 600;
  font-family: Montserrat;
  margin-left: 25px;

}

.verifybtn2 {
  height: 36px;
  width: 100%;
  float: left;
  border: 2px solid #dc3545;
  background: transparent;
  padding: 5px 20px;
  color: #dc3545;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 600;
  font-family: Montserrat;
  margin-left: 3px;

}

.carddetailbtn {
  color: rgb(0, 0, 0);
  background-color: rgb(221, 221, 221);
  float: left;
  height: 36px;
  border-radius: 6px;
  font-size: 12px;
  font-weight: 600;
  font-family: Montserrat;

}

.carddetailbtn2 {
  margin-top: -10px;
  float: left;
  margin-left: 5px;

}

.carddetailbtn2 .usethisbtn {
  margin-left: 5px;
}

.category {
  display: flex;
  gap: 20px;
  flex: 1;
}

.category .filterBox {
  height: 100%;
  width: 320px;
  padding: 12px;
}

.category .filterBox .filter {
  border: 1px solid #ccc;
  padding: 12px;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
}

.category .filterBox .filter .filterList ul {
  font-size: 14px;
  padding-left: 16px;
}

.SubmitModal.modal {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.SubmitModal .modal-dialog {
  width: inherit;
}

.SubmitModal .Details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding-top: 20px;
}

.SubmitModal .Details .imgBox {
  width: 46px;
  height: 46px;
}

.SubmitModal .Details .imgBox img {
  width: 100%;
  height: 100%;
}

.SubmitModal .Details h4 {
  font-size: 16px;
  font-weight: 600;
  color: #0B385D;
  margin: 0;
}

.SubmitModal .modal-footer {
  border: 0;
  padding-top: 0;
  justify-content: center;
  padding-bottom: 20px;
}

.ProductDetailBox {
  width: 100%;
  height: calc(100% - 120px);
  background: #fff;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;

}

.ProductDetailBox1 {
  width: 100%;
  height: calc(100% - 120px);
  background: #fff;
  padding-left: 15px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  margin-top: 50px;
}

.ProductDetail {
  width: 100%;
  background: #f7f7f7;
}

.ProductDetail .imgBox {
  width: 100%;
  padding: 15px;
  text-align: center;
}

.ProductDetail .imgBox {
  width: 100%;
  padding: 15px;
  text-align: center;
}

.ProductDetail .imgBox img {
  width: 150px;
  max-width: 100%;
  max-height: 100%;
}

.ProductDetailcheck .imgBox img {
  width: 100px;

}

.ProductDetail .detailBox {
  padding: 0 15px 15px;
}

.ProductDetail .detailBox h4 {
  font-size: 14px;
  overflow: hidden;
  text-align: center;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.ProductDetail .detailBox h3 {
  font-size: 16px;
  text-align: center;
  margin-bottom: 5px;
  font-weight: 600;
}

.ProductDetail .detailBox p {
  font-size: 12px;
  overflow: hidden;
  display: -webkit-box;
  text-align: center;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  margin: 0;
}

.inputBoxradio {
  text-align: center;
}

.QutBox {
  width: 90px;
  margin: 0;
  border: 1px solid #c5c5c5;
  margin: 0 auto;
  overflow: hidden;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;

}

.QutBox button {
  width: 26px;
  height: 30px;
  padding: 5px;
  border: 0px;
  font-size: 12px;
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
}

.QutBox input {
  width: 36px;
  padding: 5px;
  font-size: 12px;
  border: 0;
  border-left: 1px solid #c5c5c5;
  border-right: 1px solid #c5c5c5;
  text-align: center;
}

.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 22px !important;
  margin-top: var(--bs-dropdown-spacer);
}

.orderdetails {
  text-align: center;
  font-size: 20px;
  padding-top: 5px;
}

.cartBottom {
  position: sticky;
  top: 0;
  bottom: 0;
  z-index: 2;
  display: flex;
  justify-content: end;
  width: 100%;
  background: white;
  box-shadow: 0 -2px 10px 0 rgba(0, 0, 0, .1);
}

.cartBottom .subtotalLayout {
  border-top: 1px solid #f0f0f0;
  background: #fff;
  padding: 16px 22px;
}

.cartBottom .subtotalLayout .subtotal {
  padding: 16px 30px;
  min-width: 250px;
  text-transform: uppercase;
  border-radius: 2px;
  background: #fb641b;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, .2);
  border: none;
  color: #fff;
  cursor: pointer;
}

.cartBottom .subtotalLayout .subtotal span {
  font-size: 20px;
  font-weight: 500;
}


.tableBox .tables td {
  color: #0B385D;
}

.qtyoptn .QutBox {
  margin-left: 1px;
}

.badge {
  padding-left: 9px;
  padding-right: 9px;
  -webkit-border-radius: 9px;
  -moz-border-radius: 9px;
  border-radius: 9px;
  margin-top: -35px;

}

.label-warning[href],
.badge-warning[href] {
  background-color: #c67605;
}

#lblCartCount {
  font-size: 12px;
  background: #020202;
  color: #ffffff;
  padding: 0 5px;
  vertical-align: top;
  margin-left: 10px;
}

.order h4 {
  padding-left: 5px;
  padding-top: 10px;
  font-size: 18px;
}

.applybtn {
  margin-left: 5px;
  height: 35px;
  padding-top: 3px;
  font-weight: 400;
}

.applyinput {
  border-radius: 5px;
  border: 1px solid black;
  padding-left: 5px;
  width: 30%;
  height: 35px;
}

.coddes {
  padding-left: 20px;
  font-size: 11px;
}

.checkoutimg {
  border: 1px solid gray;
  width: 80%;
  border-radius: 5px;
  text-shadow: #333;
}

.tableBox .checktotal td {
  color: #ff0019
}

.checkimage img {
  height: 30px !important;
  width: 40px !important;
  margin-left: 5px;
  margin-right: 3px;
  padding-left: 10px;
}

.ProductDetailBox1 .accordion .accordionitem .accordion-button {
  background-color: #eeeeee;
  box-shadow: 0 0 0 #cecece;
}

.checkoutdescribe {
  font-size: 10px;
  padding: 5px;
}

.checkoutcalc {
  font-size: 13px;
  padding: 5px;
}

.itemtextline {
  height: 280px;
  overflow: scroll;
}

.itemtextline h6,
label {
  font-size: 13px;
}

.carddetail td input {
  width: 120%;
  margin-right: 0;
}

/* .modal-dialog table {
  width: 60%;  
  font-size: 14px;
} */
.entercard {
  color: #206caa;
}

.modalmonth {
  margin-left: 5px;

}

.flexImp {
  margin: 0;
  display: flex !important;
  align-items: center;
  justify-content: center;
}


.PriceHistoryModel {
  margin-top: 150px;
}

.PriceHistoryModel.flexImp {
  margin: 0;
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.PriceHistoryModel .modal-dialog {
  border: 0;
  max-width: fit-content !important;
}


.invoiceListModel .modal-dialog {
  border: 0;
  max-width: 70% !important;
}

.PriceHistoryModel.min-w .modal-dialog  {
  border: 0;
  max-width: fit-content !important;
  min-width: 800px;
}

.PriceHistoryModel .modal-dialog .modal-header {
  height: 60px;
  padding: 16px 26px;
}

.PriceHistoryModel .modal-dialog .modal-header .modal-title {
  font-size: 20px;
}

.PriceHistoryModel .modal-dialog .modal-body {
  padding: 10px;
}

.noitemcart {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  flex-direction: column;
  align-items: center;
}

.maindropdown {
  display: flex;
  background: #dc3545;
  justify-content: space-evenly;

}

/*--------------drop down------------*/

.dropbtn {
  background-color: #dc3545;
  color: white;
  padding: 16px;
  font-size: 20px;
  font-family: sans-serif;
  border: none;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: white;
  min-width: 160px;
  font-size: 16px;
  width: 300px;
  z-index: 100;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  border-bottom: 1px solid rgba(0, 0, 0, .075);
}

.dropdown-content a:hover {
  background-color: #ddd;
  z-index: 1000;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  background-color: #ddd;
  color: black;
}


/*-----------Media Qurey-------*/
/* Print styles */
@media print {
  @page {
      margin: 20mm; /* Set margins for the printed page */
  }

  body {
      margin: 0; /* Reset body margin for print */
  }

  /* You can hide elements that you don't want to print */
  .no-print {
      display: none;
  }
}

@media screen and (max-width: 413px) {
  .App .header .navbar .navbar-brand img {
    height: 40%;
  }

  .App .header .navbar .navbar-brand {
    height: 33px;
    margin-left: 4px;
    padding-left: 2px;
    margin-right: 0px;
  }

  .App .header .navbar .searchInputBox {
    background: #fff;
    border-radius: 8px;
    display: flex;
    width: 35%;
    height: 37px;
  }

  .App .header .navbar .searchInputBox input {
    height: 32px;
    margin: 0;
    border: 0px;
    width: 67px;
  }

  .App .header .navbar .navbar-collapse .LoginBtn {
    width: auto;
    border: 1px solid #fff;
    color: #fff;
    font-weight: 500;
    border-radius: 8px;
    margin-left: 3px;
    height: 35px;
    font-size: 8px;
  }

  .App .header .navbar .navbar-collapse .LoginBtn {
    width: auto;
    padding: 10px 5px;
    border: 1px solid #fff;
    color: #fff;
    font-weight: 500;
    border-radius: 8px;
    margin-left: 3px;
    height: 35px;
  }

  .dropbtn {
    background-color: #dc3545;
    color: white;
    font-size: 7px;
    border: none;
    padding: 4px;
  }

  .dropdown-content {
    display: none;
    font-size: 7px;
    background-color: #f1f1f1;
    z-index: 10000000;
    width: 20px;
  }

  .dropdown-content a {
    color: black;
    text-decoration: none;
    display: block;
    padding: 5px;
  }

  .carousel {
    margin-top: 0px;
  }

  .Dashboard .header .navbar {
    height: 45px;
    display: flex;
    justify-content: space-between;
    visibility: inherit;
  }

  .navbar>.container,
  .navbar>.container-fluid,
  .navbar>.container-lg,
  .navbar>.container-md,
  .navbar>.container-sm,
  .navbar>.container-xl,
  .navbar>.container-xxl {
    display: flex;
    flex-wrap: inherit;
    align-items: center;

  }

  .App .header .navbar .cartBtn {
    width: 25px;
    height: 37px;
    background: transparent;
    border: 0;
    margin-left: 0;
  }

  .App .header .navbar .profileBtn {
    display: flex !important;
    flex-direction: column;
    padding: 12px;
    align-items: center;
    justify-content: center;
  }

  .navbar-toggler-icon {
    display: inline-block;
    width: 1.0em;
    height: 1.0em;
    vertical-align: middle;
    background-image: var(--bs-navbar-toggler-icon-bg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
  }

  #lblCartCount {
    font-size: 8px;
    background: #020202;
    color: #ffffff;
    padding: 0 5px;
    vertical-align: top;
    margin-left: 12px;
    z-index: 10;
    margin-top: -30px;
  }

  .App .header .navbar .cartBtn img {
    width: 200%;
  }

  .App .header .navbar .searchInputBox button {

    background: #333;
    color: #fff;
    border: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    font-weight: 600;
    padding: 10px;
  }

  .App .header .menuHeader {
    background: #dc3545;
    margin-top: -5px;
  }

  .TopProduct .title {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    font-size: 26px;
    color: black;
    font-family: Poppins, sans-serif;
    text-transform: uppercase;
  }

  .TopProduct .TopProductList {
    background: #fff;
    box-shadow: 0px 0px 15px #efefefc9;
    padding: 16px;
    margin-bottom: 20px;
    display: inline;
  }

  .TopProduct .TopProductList h5 {
    font-size: 8px;
    font-weight: 600;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    width: 100%;
    text-overflow: ellipsis;
    text-align: center;
  }

  .TopProduct .TopProductList h5 span {
    float: right;
    font-size: 14px;
    color: #0b385d;
  }

  .TopProduct .TopProductList.ProductsGrid h5 {
    height: auto;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .TopProduct .TopProductList .Category {
    display: flex;
    gap: 6px 12px;
    flex-wrap: wrap;
    margin-top: -20px;
    justify-content: center;
  }

  .TopProduct .TopProductList .Category .CategoryList {
    width: calc(50% - 6px);
  }

  .TopProduct .TopProductList .Category .CategoryList .imgBox {
    width: 100%;
    aspect-ratio: 4/3;
  }

  .TopProduct .TopProductList .Category .CategoryList .imgBox span .backlabel {
    height: 50px;
    width: 100%;
    margin-top: -60%;
    z-index: -1;
    background-color: #EEEEEE;
    border: none;
  }

  .TopProduct .TopProductList .Category .CategoryList .imgBox span .viewbtn {
    margin-top: -125%;
    margin-left: 15%;
    background-color: #EF5350;
    border: none;
    margin-right: auto;
  }

  .TopProduct .TopProductList .Category .CategoryList .imgBox span .detailbtn {
    margin-top: -150%;
    margin-left: 62%;
    background-color: white;
    color: black;
    border: 1px solid salmon;
  }

  .TopProduct .TopProductList .Category .CategoryList .imgBox img {
    width: 120%;
    height: 120%;

  }

  .TopProduct .TopProductList .Category .CategoryList label {
    font-size: 10px;
    height: 26px;
    line-height: 13px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
  }

  .TopProduct .TopProductList .Products .imgBox {
    width: 100%;
    aspect-ratio: 4/3;
    background-color: #f3f3f3;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .TopProduct .TopProductList .Products .imgBox img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
  }

  .TopProduct .TopProductList .Products h4 {
    font-size: 14px;
    font-weight: 500;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 5px;
  }

  .TopProduct .TopProductList .Products h3 {
    color: #0b385d;
    font-size: 20px;
    font-weight: 600;
  }

  .TopProduct .TopProductList .Products h3 del {
    font-size: 14px;
    color: #ccc;
    font-weight: 500;
  }

  .TopProduct .TopProductList .Products h3 span {
    font-size: 14px;
    font-weight: 500;
    color: #333;
  }

  .TopProduct .TopProductList .Products button {
    max-width: 100%;
    padding: 8px 20px;
    background: #2d2c2c;
    color: #fff;
    border: 0px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;


  }

  .ProductDetailBox {
    width: 100%;
    height: calc(100% - 120px);
    background: #fff;
    padding: 15px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;

  }

  .footer .bottomFooter ul li img {
    height: 15px;
    width: auto;
  }

  .footer p {
    margin-top: 4px;
    margin-bottom: 10px;
    font-size: 9px;
    font-weight: 400;
  }

  .footer h4 {
    font-size: 12px;
    font-weight: 600;
    margin: 0;
    margin-bottom: 5px;
  }

  .list-unstyled {
    padding-left: 0;
    list-style: none;
    font-size: 9px;
  }

  .TopBrands .owl-carousel .owl-nav {
    position: absolute;
    z-index: -1;
    top: 3px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 0;
  }

  .footer .NewsletteSignup {
    background: #dc3545;
    color: #fff;
    padding: 16px 0;
  }

  h3.hr {
    font-size: 16px;
    font-weight: 600;
    color: #0b385d;
  }
}

.truncate-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/*--------Item Card-----------*/

.ItemCard {
  margin: 0;
  padding: 0;
  display: flex !important;
  cursor: pointer;
}

.ItemCard .CardLayout {
  margin-bottom: 20px;
  padding: 0px 20px 0px 0px;
  flex: 0 0 100%;
  max-width: 100%;
}

.ItemCard .CardLayout a {
  text-decoration: none !important;
}

.ItemCard .CardLayout .CardOutline {
  border: 1px solid rgb(234, 234, 242);
  height: 100%;
  border-radius: 8px;
  background-color: rgb(255, 255, 255);
}

.ItemCard .CardLayout .CardOutline .ImgCover {
  position: relative;
  background: white;
  height: 232px;
  width: 100%;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
}

.ItemCard .CardLayout .CardOutline .ContentLayout {
  min-height: 172px;
  display: flex;
  flex-direction: column;
  background-color: rgb(255, 255, 255);
  border-radius: 8px;
  padding: 16px;
}

.ItemCard .CardLayout .CardOutline .ContentLayout .Content {
  display: flex;
  flex-wrap: nowrap;
  -webkit-box-pack: justify;
  justify-content: space-between;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}


.ItemCard .CardLayout .CardOutline .ContentLayout .Content .PriceLayout {
  display: flex !important;
  flex-direction: row;
  align-items: center;
  padding-top: 12px;
}

.ItemCard .CardLayout .CardOutline .ContentLayout .Content .PriceLayout .Price {
  color: rgb(53, 53, 67);
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  font-family: "Mier bold";
  margin: 0px;
  padding: 0px;
}

.ItemCard .CardLayout .CardOutline .ContentLayout .Content .PriceLayout .Price .onWards {
  color: rgb(139, 139, 163);
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  font-family: "Mier demi";
  margin: 0px;
  padding: 0px;
}

.ItemCard .CardLayout .CardOutline .ContentLayout .Delivery {
  background-color: rgb(255, 255, 255);
  border-radius: 8px;
  padding-top: 12px;
}

.ItemCard .CardLayout .CardOutline .ContentLayout .Delivery .DOutLine {
  pointer-events: none;
  color: rgb(97, 97, 115);
  background: rgb(248, 248, 255);
  padding: 4px 8px;
  border-radius: 48px;
  display: inline-flex;
}

.ItemCard .CardLayout .CardOutline .ContentLayout .Delivery .DOutLine .DeliveryFee {
  color: rgb(97, 97, 115);
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  font-family: "Mier demi";
  text-align: center;
  margin: 0px;
  padding: 0px;
}

.ItemCard .CardLayout .CardOutline .ContentLayout .CartLayout {
  display: flex;
  -webkit-box-pack: justify;
  /* justify-content: space-between; */
  -webkit-box-align: center;
  align-items: center;
  background-color: rgb(255, 255, 255);
  border-radius: 8px;
  padding-top: 12px;
}

.ItemCard .CardLayout .CardOutline .ContentLayout .CartLayout .RatingBg {
  display: inline-flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  border-radius: 16px;
  padding: 4px 8px;
  background: rgb(35, 187, 117);
  fill: rgb(255, 255, 255);
}

.ItemCard .CardLayout .CardOutline .ContentLayout .CartLayout .RatingBg .RatingValue {
  color: rgb(255, 255, 255);
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  font-family: "Mier demi";
  margin: 0px;
  padding: 0px;
}

.ItemCard .CardLayout .CardOutline .ContentLayout .CartLayout .Reviews {
  color: rgb(139, 139, 163);
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  font-family: "Mier demi";
  margin: 0px;
  padding: 0px 4px;
}

/*------ accountclick ---------*/
.pricing {
  background-color: #ddd3;
  padding: 2vh 8%;
  font-weight: 500;
  line-height: 2;
  display: flex;
}

.pricing .col-3 {
  padding: 0;
}

.grid-container {
  display: grid;
  grid-template-columns: auto auto auto auto;
  padding: 10px;
  margin-top: 5%;
  gap: 25px;
  background-color: white;

}

.grid-container1 {
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  padding: 10px;
  margin-top: 1%;
  gap: 25px;
  background-color: white;

}

.grid-item {
  background-color: rgba(255, 255, 255, 0.8);
  border: 1px solid rgba(0, 0, 0, 0.8);
  border-radius: 15px;
  padding: 15px;
  font-size: 18px;
  text-align: center;

}

.grid-container .grid-item .accountimg {
  height: 40px;
  width: 40px;
  margin-bottom: 10px;
  justify-content: left;

}

.orderitemimg img {
  height: 120px;
  width: 120px;
}

.orderitemimg span {
  margin-left: 20px;
}

.grid-item h6 {
  font-size: 12px;

}

.grid-item a {
  color: #0b385d;
  text-decoration: none;
}

.grid-item1 a {

  text-decoration: none;
}

.dropbtn2 {
  background-color: #dc3545;
  color: white;
  padding: 8px;
  font-size: 16px;
  border: none;

}

.orderpage {
  background-color: white;
  margin-top: 5%;
  padding-left: 5%;
  padding-right: 5%;
}

.orderheading {
  text-align: left;

}

.topnav {
  overflow: hidden;

}

.topnav a {
  float: left;
  color: #030303;
  text-align: center;
  padding: 10px 12px;
  text-decoration: none;
  font-size: 14px;
}

.topnav a:hover {
  background-color: gray;
  color: white;
  border-radius: 10px;
}

.topnav a.active {
  background-color: #dc3545;
  color: white;
  border-radius: 10px;
}

.topnavbar {
  overflow: hidden;
}

.topnavbar a {
  float: left;
  display: block;
  color: black;
  text-align: center;
  text-decoration: none;
  font-size: 17px;
}

.topnavbar .search-container {
  float: right;
}

.topnavbar .search-container input {
  border-radius: 8px;
}

.topnavbar .search-container ::placeholder {
  font-size: 14px;
}

.topnavbar input[type=text] {
  padding: 4px 8px;
  margin-top: 8px;
  font-size: 17px;
  border: 1px solid black;
}

.topnavbar .search-container button {
  float: right;
  padding: 5px 10px;
  margin-top: 8px;
  margin-right: 16px;
  background: #ddd;
  font-size: 17px;
  border: none;
  cursor: pointer;
}

.topnavbar .search-container button:hover {
  background: #ccc;
}

.orderselect select {
  margin-left: 5px;
  border-radius: 3px;
  background-color: rgb(230, 229, 229);
}

.orderitem div {
  margin-top: 15px;
  float: left;
  display: block;
  margin-left: 25px;
  font-size: 13px;

}

.orderitem h6 {
  font-size: 13px;
}

.orderitem2 div {
  margin-top: 15px;
  float: left;
  display: block;
  margin-left: 25px;
  font-size: 13px;

}

.orderitem2 h6 {
  font-size: 13px;
  margin-left: 1000%;
  margin-top: -20px;
}

.orderitem2 h5 {
  font-size: 13px;
  margin-left: 400%;
  margin-top: -20px;
}

.orderitem2 p {
  font-size: 13px;
  margin-left: 100%;

}

.trackthisbtn {
  height: 36px;
  border: 2px solid #dc3545;
  background-color: #dc3545;
  color: #fff;
  border-radius: 6px;
  font-size: 12px;
  font-weight: 600;
  font-family: Montserrat;
  margin-top: 5px;
}

.trackthisbtn2 {
  height: 36px;
  border: 2px solid #0B385D;
  background-color: ghostwhite;
  color: #000000;
  border-radius: 6px;
  font-size: 12px;
  font-weight: 600;
  font-family: Montserrat;
  margin-top: 5px;
}

.trackthisbtn3 {
  height: 36px;
  border: 2px solid #0B385D;
  background-color: ghostwhite;
  color: #000000;
  border-radius: 6px;
  font-size: 12px;
  font-weight: 600;
  font-family: Montserrat;
  margin-top: 5px;
}

.trackthisbtn4 {
  font-size: 9px;
}

.trackinghr {
  margin-left: 15px;
}

.trackingpackage {
  text-align: center;
  margin: 40%;
}


h2 {
  color: var(--color-gray);
  font-size: var(--font-size-small);
  line-height: 1.5;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 3px;
}

section {
  margin-bottom: 2rem;
}

.pt45 {
  padding-top: 120px;
  padding-left: 200px;
}

.order-tracking {
  text-align: center;
  width: 33.33%;
  position: relative;
  display: block;
}

.order-tracking .is-complete {
  display: block;
  position: relative;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  border: 0px solid #AFAFAF;
  background-color: gray;
  margin: 0 auto;
  transition: background 0.25s linear;
  -webkit-transition: background 0.25s linear;
  z-index: 2;
}

.order-tracking .is-complete:after {
  display: block;
  position: absolute;
  content: '';
  height: 14px;
  width: 7px;
  top: -2px;
  bottom: 0;
  left: 5px;
  margin: auto 0;
  border: 0px solid #AFAFAF;
  border-width: 0px 2px 2px 0;
  transform: rotate(45deg);
  opacity: 0;
}

.order-tracking.completed .is-complete {
  border-color: #dc3545;
  border-width: 0px;
  background-color: #dc3545;
}

.order-tracking.completed .is-complete:after {
  border-color: #fff;
  border-width: 0px 3px 3px 0;
  width: 7px;
  left: 11px;
  opacity: 1;
}

.order-tracking p {
  color: #A4A4A4;
  font-size: 16px;
  margin-top: 8px;
  margin-bottom: 0;
  line-height: 20px;
}

.order-tracking a {
  text-decoration: none;
  color: #000;
}

.order-tracking p span {
  font-size: 14px;
}

.order-tracking.completed p {
  color: #000;
}

.order-tracking::before {
  content: '';
  display: block;
  height: 3px;
  width: calc(100% - 40px);
  background-color: gray;
  top: 13px;
  position: absolute;
  left: calc(-50% + 20px);
  z-index: 0;
}

.order-tracking:first-child:before {
  display: none;
}

.order-tracking.completed:before {
  background-color: #dc3545;
}

.grid-container .grid-item div h6 {
  text-align: left;
}

.grid-container .grid-item div h5 {
  text-align: left;
}