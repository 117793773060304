@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");

body {
  margin: 0;
  padding: 0;
  color: #0b385d;
  background-color: #eeeeee;
  font-family: "Courier New", Courier, monospace !important;
  font-size: 14px;
  font-weight: 600;
}

.font-fam {
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif !important;
}

::-webkit-scrollbar {
  width: 6px;
  scroll-behavior: smooth;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #3333336b;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #333;
}

.hidden {
  display: none !important;
}

.chips {
  border: 1px solid black;
  background: white;
  color: black;
  padding: 7px 16px;
  cursor: pointer;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}

.chips.active {
  background: black !important;
  color: white;
}

.opacity-0 {
  opacity: 0 !important;
}

.bg-dashboard {
  background: linear-gradient(
    180deg,
    rgba(51, 162, 72, 0) 23.96%,
    #dc3545 100%
  );
  padding: 0 0 30px !important;
}

.SpinnerBox {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #00000040;
  z-index: 555555;
}

.SpinnerBox .spinner-border {
  border-color: #0b385d;
  border-right-color: transparent;
  z-index: 5555555555;
}

.SpinnerBox1 {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  z-index: 555555;
}

.SpinnerBox1 .spinner-border1 {
  border-color: #dc3545;
  border-right-color: transparent;
  z-index: 5555555555;
}

.spinner-6 {
  width: 50px;
  aspect-ratio: 1;
  display: grid;
  mask: conic-gradient(from 15deg, #0000, #000);
  animation: s6 1s infinite steps(12);
  -webkit-animation: s6 1s infinite steps(12);
}

.spinner-6,
.spinner-6:before,
.spinner-6:after {
  background: radial-gradient(closest-side at 50% 12.5%, #f03355 96%, #0000) 50%
      0/20% 80% repeat-y,
    radial-gradient(closest-side at 12.5% 50%, #f03355 96%, #0000) 0 50%/80% 20%
      repeat-x;
}

.spinner-6:before,
.spinner-6:after {
  content: "";
  grid-area: 1/1;
  transform: rotate(30deg);
  -webkit-transform: rotate(30deg);
  -moz-transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  -o-transform: rotate(30deg);
}

.spinner-6:after {
  transform: rotate(60deg);
}

@keyframes s6 {
  100% {
    transform: rotate(1turn);
    -webkit-transform: rotate(1turn);
    -moz-transform: rotate(1turn);
    -ms-transform: rotate(1turn);
    -o-transform: rotate(1turn);
  }
}

.full-width {
  width: 100% !important;
}

.full-width .modal-dialog {
  width: 90%;
  max-width: 90%;
}

.full-height {
  height: 100% !important;
}

.width-auto {
  width: auto !important;
}

.font-xs {
  font-size: 12px !important;
}

.font-sm {
  font-size: 14px !important;
}

.font-md {
  font-size: 16px !important;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.pd-t-0 {
  padding-top: 0 !important;
}

.pd-b-0 {
  padding-bottom: 0 !important;
}

.pd-0-12 {
  padding: 0 12px !important;
}

.mr-0 {
  margin: 0 !important;
}

.mr-l-0 {
  margin-left: 0 !important;
}

.df-btn-h {
  height: 36px !important;
}

.w-max-content {
  width: max-content;
}

.justify-flex-start {
  justify-content: flex-start !important;
}

.inputBorderBottom {
  height: 1px;
  border-bottom: 1px dashed #ccc;
}

.flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.justifyContentCenter {
  justify-content: center;
}

.justifyContentStart {
  justify-content: flex-start;
}

.justifyContentEnd {
  justify-content: flex-end;
}

.justifyContentBetween {
  justify-content: space-between;
}

.alignItemCenter {
  align-items: center;
}

.w-full {
  width: "100%" !important;
}

.vertical-space-5 {
  display: block;
  width: 100%;
  height: 5px;
}

.vertical-space-10 {
  display: block;
  width: 100%;
  height: 10px;
}

.vertical-space-15 {
  display: block;
  width: 100%;
  height: 15px;
}

.vertical-space-20 {
  display: block;
  width: 100%;
  height: 20px;
}

.vertical-space-30 {
  display: block;
  width: 100%;
  height: 30px;
}

.vertical-space-40 {
  display: block;
  width: 100%;
  height: 40px;
}

.vertical-space-50 {
  display: block;
  width: 100%;
  height: 50px;
}

.vertical-space-60 {
  display: block;
  width: 100%;
  height: 60px;
}

.vertical-space-90 {
  display: block;
  width: 100%;
  height: 90px;
}

.vertical-space-120 {
  display: block;
  width: 100%;
  height: 120px;
}

.admin,
.outlets,
.reports,
.products,
.team {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.outletInputField {
  width: 560px;
  max-width: calc(100% - 30px);
  margin: 0 auto;
  /* box-shadow: 0px 0px 10px #e7e7e7; */
  border-radius: 15px;
  background: #fff;
}

.inputFormBox.LgInputField {
  width: 100%;
}

.inputFormBox.mdInputField {
  width: 80%;
} 
 
.inputFormBox .hrBox {
  width: 100%;
  padding: 20px 24px;
  background: #007BFF;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.inputFormBox .hrBox h3 {
  margin: 0;
  font-size: 24px;
  font-weight: bold;
  font-weight: 600;
  color: #fff;
}

.inputFormBox.LgInputField .ItemInwardInputBox {
  /* width: 560px; */
  margin: 0 auto;
}

.inputFormBox.LgInputField .LgInputListsBox {
  width: 560px;
  margin: 0 auto;
}

.inputFormBox.LgInputField .XlInputListsBox {
  width: 700px;
  margin: 0 auto;
}

/* .inputFormBox.mdInputField .ItemInwardInputBox{

  margin: 0 auto;
}
.inputFormBox.mdInputField .LgInputListsBox{
  width: 560px;
  margin: 0 auto;
} */

.inputFormBox .inputBoxLists .inputBoxInline {
  width: 100%;
  display: inline-flex;
}

.inputFormBox .inputBoxLists .inputBoxInline label {
  color: #19394b;
  width: 100%;
}

.PriceHistoryModel table td button {
  border: 1px solid #ccc;
  background: transparent;
  min-width: 46px;
  height: 40px;
  border-radius: 6px;
  font-size: 16px;
  padding: 5px 15px;
}

.PriceHistoryModel table td button:hover {
  background: #f1f1f1;
}

.addfBtn {
  width: 80%;
  height: 35px;
  float: right;
  border: 2px solid #dc3545;
  background-color: #dc3545;
  color: #fff;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 600;
  font-family: Montserrat;
}

.inputFormBox .inputBoxLists {
  padding: 20px 24px;
}

.inputradio {
  text-align: left;
}

.inputradio label {
  font-size: 15px;
}

.inputFormBox .inputBoxLists h5 {
  font-size: 16px;
  font-weight: 300;
  color: #b1b1b1;
  margin: 0;
}

.inputFormBox .inputBoxLists .inputBox {
  width: 100%;
}

.inputFormBox .inputBoxLists .inputBox.errorInputBox input,
.inputFormBox .inputBoxLists .inputBox.errorInputBox textarea,
.inputFormBox .inputBoxLists .inputBox.errorInputBox select,
.inputFormBox
  .inputBoxLists
  .inputBox.errorInputBox
  .css-1xnbq41-MuiAutocomplete-root
  .MuiOutlinedInput-root.MuiInputBase-sizeSmall {
  border: 1px solid #dc3545;
}

.css-1xnbq41-MuiAutocomplete-root {
  font-family: "Montserrat", sans-serif !important;
}

.inputFormBox .inputBoxLists .inputBox.errorInputBox p {
  color: #dc3545;
  font-size: 12px;
  font-weight: 600;
  margin-top: 5px;
  margin-bottom: 0px;
}

.inputFormBox .inputBoxLists .inputBox label {
  color: #19394b;
  font-size: 15px;
  font-weight: 600;
  display: block;
}

.inputFormBox .inputBoxLists .inputBox label span {
  color: #dc3545;
}

.inputFormBox .inputBoxLists .inputBox input {
  border: 1px solid #ccc;
  padding: 10px 15px;
  border-radius: 5px;
}

.inputFormBox
  .inputBoxLists
  .inputBox
  .css-1xnbq41-MuiAutocomplete-root
  .MuiOutlinedInput-root.MuiInputBase-sizeSmall {
  height: 46px;
}

.inputFormBox
  .inputBoxLists
  .inputBox
  .css-1xnbq41-MuiAutocomplete-root
  .MuiOutlinedInput-root.MuiInputBase-sizeSmall
  input {
  border: 0 !important;
  font-family: "Montserrat", sans-serif !important;
}

.inputFormBox .inputBoxLists .inputBox textarea {
  border: 1px solid #ccc;
  padding: 10px 20px;
  border-radius: 5px;
  height: 100px;
}

.inputFormBox .inputBoxLists .inputBox select {
  border: 1px solid #ccc;
  padding: 12px 20px;
  border-radius: 5px;
}

.inputFormBox .btnBox {
  display: flex;
  justify-content: right;
  padding: 0px 24px 20px;
}

.inputFormBox .totalpagenation {
  display: inline;
  justify-content: right;
  padding: 0px 24px 20px;
}

.viewBtn {
  width: auto;
  height: 36px;
  float: left;
  border: 0px solid #dc3545;
  background: transparent;
  padding: 5px 10px;
  text-decoration: underline;
  color: #dc3545;
  border-radius: 6px;
  margin-right: 20px;
  font-size: 14px;
  font-weight: 600;
  font-family: Montserrat;
}

.viewBtn span {
  text-decoration: underline;
}

.searchclearBtn {
  width: 100px;
  height: 48px;
  float: left;
  border: 2px solid #dc3545;
  background: transparent;
  padding: 5px 20px;
  color: #dc3545;
  border-radius: 9px;
  margin-right: 20px;
  font-size: 14px;
  font-weight: 500;
  font-family: Montserrat;
  margin-top: 1px;
}

.secondaryBtn {
  width: 100px;
  height: 36px;
  float: left;
  border: 2px solid #dc3545;
  background: transparent;
  padding: 5px 20px;
  color: #dc3545;
  border-radius: 6px;
  margin-right: 20px;
  font-size: 14px;
  font-weight: 600;
  font-family: Montserrat;
}

.totalrecordBtn {
  width: 100px;
  height: 36px;
  float: left;
  border: 2px solid #dc3545;
  background: transparent;
  padding: 5px 20px;
  color: #313131;
  border-radius: 6px;
  margin-right: 20px;
  font-size: 14px;
  font-weight: 600;
  font-family: Montserrat;
}

.newdfBtn {
  width: 100px;
  height: 36px;
  float: left;
  border: 2px solid #dc3545;
  margin-top: 25px;
  padding: 5px 20px;
  color: #dc3545;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 600;
  font-family: Montserrat;
}

.dfBtn {
  width: 100px;
  height: 36px;
  float: left;
  border: 2px solid #dc3545;
  background-color: #dc3545;
  padding: 5px 20px;
  color: #fff;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 600;
  font-family: Montserrat;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
}

.adddfBtn {
  width: 80%;
  height: 35px;
  float: right;
  border: 2px solid #0ac074;
  background-color: #0ac074;
  /* padding: 5px 20px; */
  color: #fff;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 600;
  font-family: Montserrat;
}

.css-1to7aaw-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
  color: #fff;
  background-color: #d21919eb !important;
}

.inputBoxLists.accordionLists {
  padding-top: 10px;
}

.inputBoxLists .accordion .accordion-item {
  border: 0;
  border-bottom: 1px solid #e5e5e5;
}

.inputBoxLists .accordion .accordion-item .accordion-button {
  box-shadow: 0 0 0 #ccc;
  background: transparent;
  font-size: 18px;
  font-weight: 300;
  color: #0b385d;
  padding: 12px 0px;
}

.inputBoxLists .accordion .accordion-body {
  padding-top: 0;
}

.inputBoxLists .btnBox {
  padding: 15px 0;
}

.inputBoxLists .row .dfBtn {
  height: 46px;
  margin-top: 23px;
  padding: 5px 10px;
}

.col-sm-20 {
  flex: 0 0 auto;
  width: 18% !important;
}

.inputBoxLists .tableBox {
  border: 1px solid #ccc;
  border-radius: 10px;
  overflow: auto;
}

.inputBoxLists .tableBox::-webkit-scrollbar {
  height: 5px;
}

.inputBoxLists table {
  width: 100%;
  margin: 0;
  font-size: 14px;
}

.inputBoxLists table th {
  background: #f2f2f2;
  padding: 8px;
  text-wrap: nowrap;
}

.inputBoxLists table th:first-child {
  padding-left: 16px;
}

.inputBoxLists table th:last-child {
  padding-right: 16px;
}

.inputBoxLists table td {
  padding: 8px;
  --bs-table-bg-type: tranprent !important;
}

.inputBoxLists table td:first-child {
  padding-left: 16px;
}

.inputBoxLists table td:last-child {
  padding-right: 16px;
}

.inputBoxLists table tr:last-child td {
  border: 0px;
  vertical-align: middle;
}

.inputBoxLists table tr:last-child td button.delete {
  width: 40px;
  height: 26px;
  color: #dc3545;
  border: 0px solid #dc3545;
  background: transparent;
  border-radius: 5px;
}

.SearchBox {
  width: 100%;
  margin: 0 auto;
  display: flex;
  gap: 20px;
}

.SearchBox select {
  height: 50px;
  border-radius: 10px;
  border: 1px solid #ccc;
  padding: 5px 20px;
  width: min-content !important;
  color: #000;
}

.SearchBox option {
  background-color: #dc3545;
  color: white;
  border-radius: 7px;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  -o-border-radius: 7px;
}

.SearchBox .input-group {
  border: 1px solid #ccc;
  border-radius: 10px;
  overflow: hidden;
}

.SearchBox .input-group input {
  border: 0px;
  padding: 12px 20px;
  font-weight: 500;
  color: #0b385d;
}

.SearchBox .input-group button {
  height: 100%;
  border: 0;
  padding: 8px 16px;
  color: #dc3545;
  font-size: 20px;
  background-color: lightgray;
}

.SearchBox .input-group button:hover {
  background: lightgray;
}

.tableListDetails {
  border: 1px solid #ccc;
  border-radius: 10px;
  overflow: auto;
  width: fit-content;
  max-width: 100%;
  margin: 0 auto;
  max-height: 500px;
}

.tableListDetails::-webkit-scrollbar {
  height: 5px;
}

.tableListDetails table {
  width: 100%;
  margin: 0;
  font-size: 14px;
}

.tableListDetails table th {
  background: #504c4c;
  padding: 8px;
  text-wrap: nowrap;
  color: #fff;
}

.tableListDetails table thead {
  position: sticky;
  top: 0;
}

.tableListDetails table th:first-child {
  padding-left: 16px;
}

.tableListDetails table th:last-child {
  padding-right: 16px;
}

.tableListDetails table td {
  padding: 8px;
  --bs-table-bg-type: tranprent !important;
  vertical-align: middle;
}

.tableListDetails table tr:nth-child(even) td {
  padding: 8px;
  --bs-table-bg-type: #f7f7f7 !important;
}

.tableListDetails table td:first-child {
  padding-left: 16px !important;
}

.tableListDetails table td:last-child {
  padding-right: 16px !important;
}

.tableListDetails table tr:last-child td {
  border: 0px;
}

.tableListDetails table td button {
  border: 1px solid #ccc;
  background: transparent;
  min-width: 46px;
  height: 40px;
  border-radius: 6px;
  font-size: 16px;
  padding: 5px 15px;
  white-space: nowrap;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
}

.tableListDetails table td button:hover {
  background: #f1f1f1;
}

.tableListDetails table td button.changeBtn {
  height: 34px;
  font-size: 14px;
}

.tableListDetails table td button.Add {
  color: #0b385d;
}

.tableListDetails table td button.Edit {
  color: #3d6acc;
}

.tableListDetails table td button.delete {
  color: #dc3545;
}

.Dashboard {
  width: 100%;
  height: 100%;
  background: #f9f9f9;
  position: fixed;
  top: 0;
  left: 0;
}

.Dashboard .header {
  background: #dc3545;
  height: 80px;
}

.Dashboard .header .navbar {
  height: 80px;
  display: flex;
  justify-content: space-between;
}

.Dashboard .header .navbar .menuBox {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Dashboard .header .navbar .SideMenuBtn {
  width: 46px !important;
  height: 46px !important;
  background: transparent;
  color: #fff;
  border: 0px;
  padding: 5px;
  font-size: 26px;
  margin-right: 20px;
}

.Dashboard .header .navbar .HomeBtn {
  width: 46px !important;
  height: 46px !important;
  background: transparent;
  color: #fff;
  border: 0px;
  padding: 5px;
  font-size: 26px;
  margin-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Dashboard .header .navbar .SearchInput {
  border: 0px;
  background-color: #fff;
  border-radius: 10px;
  height: 46px;
  overflow: hidden;
  width: 50% !important;
}

.Dashboard .header .navbar .SearchInput input {
  border: 0px;
  padding: 5px 20px;
}

.Dashboard .header .navbar .SearchInput button {
  border: 0px;
  padding: 5px 15px;
  font-size: 20px;
  color: #0b385d;
  z-index: -1;
}

.Dashboard .header .navbar .dropdown button {
  background: transparent;
  border: 1px solid #fff;
  margin-left: 20px;
  border-radius: 1px;
  border-radius: 10px;
  height: 100%;
}

.Dashboard .header .navbar .dropdown button img {
  width: 30px;
  height: 30px;
  border: 1px solid #fff;
  border-radius: 40px !important;
  -webkit-border-radius: 40px !important;
  -moz-border-radius: 40px !important;
  -ms-border-radius: 40px !important;
  -o-border-radius: 40px !important;
}

.Dashboard .header .navbar .navbar-collapse {
  display: contents !important;
  justify-content: flex-end;
}

.Dashboard .header .navbar .notification {
  width: 46px;
  height: 46px;
  margin-left: 20px;
  font-size: 22px;
  color: #fff;
  text-align: center;
}

.Dashboard .sidebar {
  width: 320px;
  height: 100%;
  padding: 0;
  top: 80px;
  background: #232323;
  overflow: auto;
  transition-delay: 0.06s;
  transition-duration: 0.06s;
}

.Dashboard .sidebar.sidebarHide {
  width: 0;
}

.Dashboard .sidebar::-webkit-scrollbar {
  width: 8px;
}

/* Track */
.Dashboard .sidebar::-webkit-scrollbar-track {
  background: #232323;
}

/* Handle */
.Dashboard .sidebar::-webkit-scrollbar-thumb {
  background: #8080806b;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

/* Handle on hover */
.Dashboard .sidebar::-webkit-scrollbar-thumb:hover {
  background: #949494;
}

.Dashboard .sidebar .accordion {
  width: 100%;
  color: #fff;
  border: 0;
  padding: 16px;
}

.Dashboard .sidebar .accordion .accordion-item {
  background: transparent;
  border: 0;
  margin-bottom: 10px;
}

.Dashboard .sidebar .accordion .accordion-item .accordion-header button {
  background: #ffffff0f;
  border-radius: 10px;
  border: 0px;
  box-shadow: none;
  color: #fff;
  padding: 15px 45px 15px 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.Dashboard .sidebar .accordion .accordion-item .accordion-header button img {
  width: 22px;
  height: 22px;
}

.Dashboard .sidebar .accordion .accordion-item .accordion-header button::after {
  position: absolute;
  right: 20px;
  width: 12px;
  height: 12px;
  border-left: 2px solid #fff;
  border-bottom: 2px solid #fff;
  background-image: none;
  margin: 0;
}

.Dashboard
  .sidebar
  .accordion
  .accordion-item
  .accordion-header
  button.accordion-button::after {
  top: 22px;
  transform: rotate(-225deg);
}

.Dashboard
  .sidebar
  .accordion
  .accordion-item
  .accordion-header
  button.collapsed::after {
  top: 16px;
  transform: rotate(-45deg) !important;
}

.Dashboard .sidebar .accordion .accordion-item .accordion-body {
  padding: 10px 0 10px 15px;
}

.Dashboard .sidebar .accordion .accordion-item .accordion-body ul {
  margin: 0;
}

.Dashboard .sidebar .accordion .accordion-item .accordion-body ul li {
  color: #fff;
}

.Dashboard .sidebar .accordion .accordion-item .accordion-body ul li a {
  width: 100%;
  min-height: 40px;
  color: #fff;
  display: flex;
  text-decoration: none;
  padding: 5px 15px;
  align-items: center;
  font-size: 14px;
}

.Dashboard .sidebar .accordion .accordion-item .accordion-body ul li a img {
  width: 20px;
  height: 20px;
}

.Dashboard .sidebar .accordion .accordion-item .accordion-body ul li a svg {
  margin-right: 8px;
}

.Dashboard .sidebar .accordion .accordion-item .accordion-body ul li a:hover {
  background: #dc3545;
}

.bg-DC {
  background: #dc3545 !important;
}

.Dashboard .DashboardBody {
  display: flex;
  height: calc(100% - 80px);
}

.Dashboard .DashboardBody .DashboardDetail {
  flex: 1;
  padding: 15px 5px;
  overflow: auto;
}

.Dashboard .DashboardBody .DashboardDetail .DashboardDetailListBox {
  display: flex;
  gap: 15px;
}

.Dashboard .DashboardBody .DashboardDetail .DashboardDetailList {
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.04);
  padding: 20px;
  margin-bottom: 16px;
}

.Dashboard .DashboardBody .DashboardDetail .DashboardDetailList h2 {
  font-size: 30px;
  margin: 0;
  color: #0b385d;
  font-weight: bold;
}

.Dashboard .DashboardBody .DashboardDetail .DashboardDetailList label {
  font-size: 14px;
  color: #999999;
}

.Dashboard .DashboardBody .DashboardDetail .DashboardDetailList img {
  width: 46px;
  height: 46px;
}

.DashBoard {
  width: 100%;
  padding: "20px";
}

.chartBox {
  width: 100%;
  height: 100%;
  max-height: 470px;
  display: flex;
  justify-content: center;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.04);
}

.chartBox .BarChart {
  width: 100% !important;
  height: 100% !important;
  min-height: 100% !important;
  max-height: 100% !important;
  padding: 20px;
}

.chartBox .BarChart .apexcharts-canvas {
  width: 100% !important;
  /* height: 100% !important;
  min-height: 100% !important;
  max-height: 100% !important; */
}

.chartBox .BarChart .apexcharts-canvas svg {
  width: 100%;
}

.ApprovalsBox {
  width: 100%;
  height: 100%;
  background: #fff;
  max-height: 470px;
  padding: 20px 15px 20px 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.04);
  display: flex;
  flex-direction: column;
}

.ApprovalsListsBox {
  flex: 1;
  overflow: auto;
  padding-right: 5px;
}

.ApprovalsListsBox::-webkit-scrollbar {
  width: 6px;
  border-radius: 10px;
}

/* Track */
.ApprovalsListsBox::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

/* Handle */
.ApprovalsListsBox::-webkit-scrollbar-thumb {
  background: #33333334;
  border-radius: 10px;
}

/* Handle on hover */
.ApprovalsListsBox::-webkit-scrollbar-thumb:hover {
  background: #33333356;
}

.ApprovalsBox h3 {
  font-size: 20px;
  color: #0b385d;
  font-weight: 700;
  margin: 0 0 10px;
}

.ApprovalsLists {
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  background-color: #f9f9f9;
  border-radius: 6px;
  padding: 6px 12px;
  margin-bottom: 10px;
}

.ApprovalsLists h4 {
  font-size: 18px;
  height: 18px;
  margin: 2px 0 0;
  color: #0b385d;
  font-weight: bold;
}

.ApprovalsLists label {
  font-size: 12px;
  color: #0b385d;
  font-weight: 400;
}

.ApprovalsLists img {
  width: 36px;
  height: 36px;
}

.Pos {
  height: 100%;
  overflow: hidden;
}

.Pos .PosInputGroup {
  width: 100%;
  box-shadow: 0px 0px 10px #e7e7e7;
  border: 1px solid gray;
  background: #fff;
  /* overflow: scroll;
  position: relative; */
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.Pos .PosInputGroup input {
  border: 0;
  height: 46px;
  padding-left: 35px;
  font-size: 14px;
}

.Pos .PosInputGroup .btn-outline-secondary {
  width: 46px;
  height: 46px;
  background: #6571ff;
  border: 0px;
  color: #fff;
}

.Pos .PosInputGroup .inputPlaceholderIcon {
  position: absolute;
  left: 15px;
  top: 15px;
  color: #0b385d;
  z-index: 5;
}

.Pos .TypeaheadSelectInput {
  width: 100%;
  height: 46px;
  box-shadow: 0px 0px 10px #e7e7e7;
  background: #fff;
  position: relative;
  border-radius: 10px;
}

.TypeaheadSelectInput2 {
  width: 50%;
  height: 40px;
  box-shadow: 0px 0px 10px #e7e7e7;
  font-size: 14px;
  background: #fff;
  position: relative;
  border-radius: 8px;
  padding: 10px 5px;
}

.Pos .TypeaheadSelectInput .rbt {
  height: 100%;
  border: 0px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.Pos .TypeaheadSelectInput .rbt input {
  border: 0px;
  padding-left: 50px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.Pos .TypeaheadSelectInput .inputPlaceholderIcon {
  position: absolute;
  left: 15px;
  top: 15px;
  color: #0b385d;
  z-index: 5;
  border-radius: 10px;
}

.Pos .ProductListsDetilBox {
  width: 100%;
  /* height: auto; */
  height: calc(90%);
  background: #fff;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  /* box-shadow: 0px 0px 10px #e7e7e7; */
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  overflow: scroll;
}

.Pos .ProductListsDetilBox .tableBox {
  width: 100%;
  overflow-y: scroll;
  height: 450px;
}

.Pos .ProductListsDetilBox .tableBox .table th {
  font-size: 12px;
  text-wrap: nowrap;
  text-align: center;
}

.Pos .ProductListsDetilBox .tableBox .table th:first-child {
  text-align: left;
  padding-left: 0;
}

.Pos .ProductListsDetilBox .tableBox .table td {
  font-size: 14px;
  vertical-align: middle;
  text-align: left;
}

.Pos .ProductListsDetilBox .tableBox .table td:first-child {
  padding-left: 0px;
  text-align: left;
  flex: 1;
}

.Pos .ProductListsDetilBox .tableBox .table td h4 {
  font-size: 14px;
  font-weight: 600;
  margin: 0;
}

.Pos .ProductListsDetilBox .tableBox .table td span {
  padding: 2px 6px;
  background: #dc354538;
  font-size: 12px;
  margin-right: 8px;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
}

.Pos .ProductListsDetilBox .tableBox .table td svg {
  font-size: 12px;
  color: #504c4c;
}

.Pos .ProductListsDetilBox .tableBox .table td .QutBox {
  width: 200px;
  margin: 0;
  border: 1px solid #c5c5c5;
  margin: 0 auto;
  overflow: hidden;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.Pos .ProductListsDetilBox .tableBox .table td .QutBox button {
  width: 56px;
  height: 30px;
  padding: 5px;
  border: 0px;
  font-size: 12px;
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
}

.Pos .ProductListsDetilBox .tableBox .table td .QutBox input {
  width: 56px;
  padding: 5px;
  font-size: 12px;
  border: 0;
  border-left: 1px solid #c5c5c5;
  border-right: 1px solid #c5c5c5;
  text-align: center;
}

.Pos .ProductListsDetilBox .inputBox {
  width: 100%;
  position: relative;
}

.Pos .ProductListsDetilBox .inputBox input {
  width: 100%;
  height: 40px;
  border: 1px solid #c5c5c5;
  padding: 5px 32px 5px 16px;
  font-size: 14px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.Pos .ProductListsDetilBox .inputBox svg {
  position: absolute;
  top: 12px;
  right: 15px;
  z-index: 5;
  color: #0b385d;
}

.Pos .priceDetailBox {
  height: 100%;
  float: left;
  position: relative;
}

.Pos .priceDetailBox ul li {
  font-size: 15px;
  text-align: right;
  /* margin-bottom: 5px; */
  color: #303030;
  font-weight: 600;
  display: inline-block;
}

.Pos .priceDetailBox ul li:last-child {
  font-weight: 700;
  color: #504c4c;
}

.Pos .ProductListsDetilBox button {
  text-wrap: nowrap;
  padding: 5px;
  box-shadow: 0px 0px 10px #e7e7e7;
  border: 0px;
}

.HoldBtn {
  background: #ff679b !important;
  border: none !important;
  font-size: 14px !important;
}

.viewbillBtn {
  background: #0b3f8f !important;
  border: none !important;
}

.resetBtn {
  background: #0228ff !important;
  border: none !important;
  font-size: 11px !important;
}

.resetBtn.font-md {
  font-size: 13px !important;
}

.SuccessBtn {
  background: #0ac074 !important;
  border: none !important;
  font-size: 11px !important;
}

.SuccessBtn.font-md {
  font-size: 13px !important;
  white-space: nowrap;
}

.Pos .SearchInputBox {
  width: 100%;
  height: 46px;
  display: flex;
  box-shadow: 0px 0px 10px #e7e7e7;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.Pos .SearchInputBox svg {
  height: 22px;
  width: 22px;
  padding: 12px 12px 12px 20px;
}

.Pos .SearchInputBox input {
  display: flex;
  flex: 1;
  border: 0px;
}

.Pos .ButtonsList {
  display: flex;
  gap: 16px;
}

.Pos .ButtonsList button {
  width: 46px;
  height: 46px;
  background: #6571ff;
  border: 0px;
  padding: 5px;
  font-size: 22px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.Pos .ProductDetailBox {
  width: 100%;
  height: 50%;
  background: #fff;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  /* box-shadow: 0px 0px 10px #e7e7e7; */
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.Pos .ProductDetail {
  width: 100%;
  background: #f7f7f7;
}

.Pos .ProductDetail .imgBox {
  width: 100%;
  padding: 15px;
  text-align: center;
}

.Pos .ProductDetail .imgBox img {
  width: 80px;
  max-width: 50%;
  max-height: 50%;
}

.Pos .ProductDetail .detailBox {
  padding: 0 15px 15px;
}

.Pos .ProductDetail .detailBox h4 {
  font-size: 13px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.Pos .ProductDetail .detailBox h3 {
  font-size: 13px;
  margin-bottom: 5px;
}

.Pos .ProductDetail .detailBox p {
  font-size: 12px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  margin: 0;
}

.Pos .CustomerDetail .tableBox table td {
  padding: 5px 0;
  font-size: 14px;
  border-bottom: 1px dashed #c5c5c5;
  text-align: left;
  text-transform: capitalize;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  outline: 0;
  margin-top: -27px !important;
}

.Pos .CustomerDetail .tableBox table td:last-child {
  text-align: right;
  font-weight: 600;
}

.loader {
  text-align: center;
  font-size: 16px;
}

.PriceHistoryModel .modal-dialog {
  border: 0;
  max-width: fit-content;
}

.PriceHistoryModel .modal-dialog .modal-header {
  height: 60px;
  padding: 16px 26px;
}

.PriceHistoryModel .modal-dialog .modal-header .modal-title {
  font-size: 20px;
}

.PriceHistoryModel .modal-dialog .modal-body {
  padding: 26px;
}

.modal-dialog .tableBox {
  border: 1px solid #ccc;
  border-radius: 10px;
  overflow: auto;
  position: relative;
  max-height: 481px;
}

.modal-dialog .tableBox::-webkit-scrollbar {
  height: 5px;
}

.modal-dialog table {
  width: 100%;
  margin: 0;
  font-size: 14px;
}

.modal-dialog table th {
  background: #f2f2f2;
  padding: 8px;
  text-wrap: nowrap;
}

.modal-dialog table th:first-child {
  padding-left: 16px;
}

.modal-dialog table th:last-child {
  padding-right: 16px;
}

.modal-dialog table td {
  padding: 8px;
  --bs-table-bg-type: tranprent !important;
}

.modal-dialog table td:first-child {
  padding-left: 16px;
}

.modal-dialog table td:last-child {
  padding-right: 16px;
}

.modal-dialog table tr:last-child td {
  border: 0px;
  vertical-align: middle;
}

.modal-dialog table tr:last-child td button.delete {
  width: 40px;
  height: 26px;
  color: #dc3545;
  border: 0px solid #dc3545;
  background: transparent;
  border-radius: 5px;
}

.SubmitModal.modal {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.SubmitModal .modal-dialog {
  width: inherit;
}

.SubmitModal .Details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding-top: 20px;
}

.SubmitModal .Details .imgBox {
  width: 46px;
  height: 46px;
}

.SubmitModal .Details .imgBox img {
  width: 100%;
  height: 100%;
}

.SubmitModal .Details h4 {
  font-size: 16px;
  font-weight: 600;
  color: #0b385d;
  margin: 0;
}

.SubmitModal .modal-footer {
  border: 0;
  padding-top: 0;
  justify-content: center;
  padding-bottom: 20px;
}

.paymentOptionModel .modal-dialog .modal-content {
  width: 660px;
  max-width: 100%;
}

.accordion .inputBox input {
  height: 46px;
  padding-left: 16px;
  border: 1px solid #ccc;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
}

.accordion .inputBox .input-group {
  height: 46px;
  border: 1px solid #ccc;
  overflow: hidden;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
}

.accordion .inputBox .VerifyedUPI {
  color: #0ac074;
  margin-top: 5px;
  font-weight: 600;
  text-transform: uppercase;
}

.accordion .inputBox .input-group input {
  border: 0px;
  height: 100%;
  padding-left: 16px;
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
}

.accordion .inputBox .input-group button {
  border: 0px;
  background: transparent;
  color: #0b385d;
  padding-right: 20px;
}

.accordion
  .inputBox
  .MuiFormControl-root.MuiFormControl-fullWidth
  .MuiInputBase-root.MuiOutlinedInput-root {
  height: 46px;
  padding-top: 0;
  padding-left: 0;
  padding-bottom: 0;
}

.accordion .inputBox .MuiFormControl-root.MuiFormControl-fullWidth input {
  height: auto !important;
  padding-left: 16px;
  border: 0;
}

.accordion .btnBox button.lgBtn {
  height: 46px;
}

.paymentOptionModel .accordion-button:focus {
  box-shadow: 0px 0px 0px #ccc !important;
}

.address {
  text-align: center;
  line-height: 6px;
}

.taxinvoice {
  margin-top: 3%;
  text-align: center;
  line-height: 6px;
}

.Customer {
  margin-top: 3%;
  text-align: center;
  line-height: 7px;
}

hr {
  border-top: dashed 3px;
}

.invoiceSpan {
  font-weight: bold;
}

.bottom {
  text-align: center;
  line-height: 6px;
  font-weight: bolder;
}

.itemtable {
  margin-left: auto;
  margin-right: auto;
  text-align: left;
  width: 100%;
}

.taxtable {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  width: 60%;
}

.amounttable {
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding-left: 10%;
}

.tablesummeryvalue {
  font-weight: bolder;
}

.bottomDate {
  text-align: right;
}

.heading {
  border-bottom: 3px dashed black;
}

.taxvalue {
  padding-left: 20%;
}

.printme {
  display: none;
}

@media print {
  .no-printme {
    display: none;
  }

  .printme {
    display: block;
  }
}

.inputFormBox img {
  width: 550px;
}

.inputFormBox a {
  margin-left: 30px;
}

.forgotbox {
  margin-top: 3%;
}

.inputBoxaddbtn {
  align-items: center;
}

.inputBoxaddbtn .textbox {
  margin-left: 20%;
  border-radius: 5px;
  height: 35px;
}

.inputBoxaddbtn .checkbox {
  margin-left: 10%;
  border-radius: 4px;
}

.autoComplete {
  width: 50%;
  height: 40px;
  box-shadow: 0px 0px 10px #e7e7e7;
  font-size: 14px;
  background: #fff;
  position: relative;
  border-radius: 8px;
  padding: 10px 5px;
  border: 2px solid lightgrey;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}

.box-shadow1 {
  box-shadow: 0px 0px 10px 1px rgba(82, 56, 56, 0.75);
}

.box-shadow2 {
  box-shadow: 0px 0px 10px 1px rgba(151, 114, 114, 0.75);
}

.box-shadow3 {
  box-shadow: 0px 0px 8px 1px rgba(119, 90, 90, 0.75);
}

.box-shadow4 {
  box-shadow: 0px 0px 5px 1px rgba(119, 90, 90, 0.75);
}

.box-shadow5 {
  box-shadow: 0px 0px 8px 1px rgb(151 146 146 / 22%);
}

.box-shadow6 {
  box-shadow: 0px 0px 8px 1px rgb(16 14 14 / 78%);
}

.box-shadow7 {
  box-shadow: 0 0 6px 0 rgb(0 0 0 / 25%);
}

.box-shadow8 {
  box-shadow: 0px 0px 5px 1px rgb(102 92 92 / 24%);
}

.box-shadow9 {
  box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, 0.5),
    7px 7px 20px 0px rgba(0, 0, 0, 0.1), 4px 4px 5px 0px rgba(0, 0, 0, 0.1);
}

.box-shadow10 {
  box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, 0.5),
    7px 7px 20px 0px rgba(0, 0, 0, 0.1), 4px 4px 5px 0px rgba(0, 0, 0, 0.1);
}

.box-shadow11 {
  box-shadow: 4px 4px 6px 0 rgb(255 255 255 / 50%),
    -4px -4px 6px 0 rgb(116 125 136 / 25%),
    inset -4px -4px 6px 0 rgb(255 255 255 / 20%),
    inset 4px 4px 6px 0 rgb(0 0 0 / 28%);
}

.box-shadow12 {
  box-shadow: 4px 4px 6px 0 rgb(177 165 165 / 50%),
    -4px -4px 6px 0 rgb(116 125 136 / 25%),
    inset -4px -4px 6px 0 rgb(209 164 164 / 20%),
    inset 4px 4px 6px 0 rgb(0 0 0 / 28%);
}

.box-shadow13 {
  box-shadow: 0px 20px 50px 0px rgb(1 1 64 / 8%);
}

.box-shadow14 {
  box-shadow: inset 2px 2px 2px 0px rgb(153 153 153 / 50%),
    7px 7px 20px 0px rgb(0 0 0 / 10%), 4px 4px 5px 0px rgb(0 0 0 / 10%);
}

.box-shadow16 {
  box-shadow: 4px 4px 6px 0 rgb(177 165 165 / 8%),
    -4px -4px 6px 0 rgb(116 125 136 / 8%),
    inset -4px -4px 6px 0 rgb(64 50 50 / 14%),
    inset 4px 4px 6px 0 rgb(0 0 0 / 14%);
}

[data-cue="slideInLeft"],
[data-cues="slideInLeft"] > * {
  opacity: 0;
}

@keyframes slideInLeft {
  from {
    opacity: 0;
    transform: translateX(-30px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

[data-cue="slideInRight"],
[data-cues="slideInRight"] > * {
  opacity: 0;
}

@keyframes slideInRight {
  from {
    opacity: 0;
    transform: translateX(30px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

[data-cue="slideInDown"],
[data-cues="slideInDown"] > * {
  opacity: 0;
}

@keyframes slideInDown {
  from {
    opacity: 0;
    transform: translateY(-30px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

[data-cue="slideInUp"],
[data-cues="slideInUp"] > * {
  opacity: 0;
}

@keyframes slideInUp {
  from {
    opacity: 0;
    transform: translateY(30px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.dashboard-modal {
  padding: 32px 22px !important;
  position: relative;
}

.gap-1 {
  gap: 0.5rem;
}

.gap-2 {
  gap: 1rem;
}

.gap-4 {
  gap: 2rem;
}

.dashboard-modal .main-content {
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  height: 100%;
}

.col-span-6 {
  grid-column: span 6 / span 6;
}

.col-span-4 {
  grid-column: span 4 / span 4;
}

.dashboard-modal .main-content .image-content {
  display: flex;
  flex-direction: column;
  gap: 3.5rem;
  border-radius: 40px;
  -webkit-border-radius: 40px;
  -moz-border-radius: 40px;
  -ms-border-radius: 40px;
  -o-border-radius: 40px;
}

.dashboard-modal .main-content .image-content .heading-ganga {
  font-size: 1.5rem;
  line-height: 2rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.dashboard-modal .main-content .grid-content {
  font-weight: 700;
  color: black;
  font-size: 1.25rem;
  line-height: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.dashboard-modal .main-content .grid-content .border-b {
  width: 80px;
  border-bottom: 6px solid orangered;
  border-radius: 20px;
}

.dashboard-modal .main-content .quick-access {
  border-radius: 0.375rem;
  outline: 2px solid transparent;
  outline-offset: 2px;
  height: 100%;
  transition: all 0.2s ease-in-out;
  transform: translateY(0);
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.quick-access:hover {
  transform: translateY(-0.15rem);
  box-shadow: 0 0.25rem 0.75rem rgba(30, 34, 40, 0.15);
  color: #fff;
  opacity: 1;
}

.quick-access.quick-access-white:hover,
.quick-access[class*="quick-access-soft-"]:hover {
  box-shadow: 0 0.25rem 0.75rem rgba(30, 34, 40, 0.05);
}

.h-87 {
  height: 87%;
}

.calenderWrap {
  display: inline-block;
  position: relative;
}

.calenderElement {
  position: absolute;
  top: 40px;
  left: 50%;
  transform: translate(-50%);
  z-index: 999;
  box-shadow: 0px 0px 2px rgb(0, 0, 7);
  -webkit-transform: translate(-50%);
  -moz-transform: translate(-50%);
  -ms-transform: translate(-50%);
  -o-transform: translate(-50%);
}

.text-rotate {
  transform: rotate(270deg);
  /* width: 150px; */
  transform-origin: 0 0;
  position: absolute;
  top: 50%;
  left: 10px;
}

.text-rotate2 {
  transform: rotate(270deg);
  width: 160px;
  transform-origin: 0 0;
  position: absolute;
  top: 50%;
  left: 90%;
}

.barcode-price-label {
  border-bottom: 3px solid;
  width: fit-content;
  font-weight: 700;
}

.barcode-price {
  font-weight: bolder;
  font-size: 18px;
  margin-bottom: 0px;
}

.form-check {
  width: 30px !important;
  height: 30px !important;
  border-width: 2px;
}

.form-check-input {
  padding: 10px !important;
  margin-right: 25px;
  border: 1px solid #909090 !important;
}

.body-height {
  height: 60vh;
  overflow-y: scroll;
}

.barcode-alignment {
  width: 50%;
  margin: 0 auto;
  border: 1px solid;
}

.label-style {
  font-size: 18px;
}

.barcode-config-fields {
  width: 80%;
  max-width: calc(100% - 30px);
  margin: 0 auto;
  box-shadow: 0px 0px 10px #e7e7e7;
  border-radius: 15px;
  background: #fff;
}

.invoice-list-title {
  color: black;
  font-weight: bold;
  font-size: 19px;
  margin-left: 20px;
}

.invoice-list-titleValue {
  color: red;
  font-weight: bold;
  font-size: 19px;
}
