.flex-center {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.categories {
    background: white;
}

.categories .title {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    font-size: 24px;
    color: #383838;
    text-transform: uppercase;
}

.categories .title span {
    display: flex;
    flex-direction: column;
    width: fit-content;
    margin-bottom: 20px;
    border-bottom: 1px solid black;
    padding-bottom: 25px;
}

.categories .title .main {
    width: fit-content;
    padding-bottom: 15px;
}

.categories .title .subtitle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    font-size: 14px;
    color: #777777;
}

.categories .section {
    position: relative;
    width: 100%;
}

.categories .owl-theme {
    display: block;
    position: relative;
    z-index: 1;
    width: 100%;
    -webkit-tap-highlight-color: transparent;
}

.categories .owl-carousel .owl-stage-outer {
    z-index: 1;
    position: relative;
    overflow: hidden;
    transform: translate3d(0, 0, 0);
}

.categories .owl-carousel .owl-stage {
    display: flex;
    position: relative;
}

.categories .owl-item {
    position: relative;
    float: left;
    min-height: 1px;
    user-select: none;
    padding: 0 15px;
    width: '244.398px'
}

.categories .owl-carousel .owl-nav {
    position: absolute;
    z-index: 1;
    top: calc(50% - 23px);
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.categories .owl-carousel .owl-nav button {
    width: 40px;
    height: 50px;
    background: #2d2c2c;
    color: #fff;
    font-size: 36px;
    margin: 0;
    z-index: 555;
    line-height: 25px;
    padding-bottom: 5px !important;
    text-align: center;
    border-radius: 5px;
}

.categories .owl-carousel .owl-nav button span {
    height: 36px;
}

.product-item-grid {
    text-align: var(--text-align);
    line-height: 1.4;
    display: flex;
    --text-align: center;
    --wd-btn-inset: 10px;
    --wd-prod-gap: 12px;
    --wd-prod-bg-sp: 0;
    --wd-prod-bg-cont-sp: 0;
    --wd-prod-trim: calc((var(--wd-text-line-height) * -.5em) + .5em);
    flex-direction: column;
    gap: 12px;
}

.product-grid-item .product-wrapper {
    display: flex;
    flex-direction: column;
    gap: 12;
}

.product-element-top {
    position: relative;
    overflow: hidden;
    border-radius: var(--wd-brd-radius);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.product-image-link {
    position: relative;
    display: block;
}

.wd-buttons {
    z-index: 4;
    display: flex;
    flex-direction: column;
    border-radius: 2px;
    background: white;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, .1);
    transition: all .3s ease;
}

.wd-buttons.wd-pos-r {
    position: absolute;
    opacity: 0;
    visibility: hidden;
    transform: translateX(20px)translateZ(0);
    top: 0px;
    inset-inline-end: 10px;
}

.product-item-grid:hover .wd-buttons.wd-pos-r {
    opacity: 1;
    visibility: visible;
    transform: none
}

.wd-buttons span {
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    flex-direction: column;
    color: black;
    width: 56px;
    height: 45px;
    font-weight: 400;
    font-size: 0 !important;
    line-height: 0;
}

.wd-buttons span svg {
    color: black;
    cursor: pointer;
    background-color: white;
}

.wd-buttons span svg:hover {
    color: #333333;
}

.product-grid-item .product-image-link img {
    width: 100%;
    object-fit: contain;
    height: 225px;
}

.product-labels {
    position: absolute;
    top: 7px;
    inset-inline-start: 7px;
    z-index: 1;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 10px;
    max-width: 50%;
    transition: opacity .3s ease;
}

.product-labels.labels-rounded .product-label {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 7px;
    min-height: 50px;
    border-radius: 35px;
    font-size: 14px;
}

.product-label.onsale {
    background-color: var(--wd-primary-color);
    direction: ltr;
}

.product-label {
    padding: 5px 10px;
    min-width: 50px;
    color: #fff;
    text-align: center;
    text-transform: uppercase;
    word-break: break-all;
    font-weight: 600;
    font-size: 12px;
    line-height: 1.2;
}

.product-grid-item .wd-entities-title {
    font-size: inherit;
    line-height: inherit;
    margin-block: -.2em;
    font-weight: 500;
    font-size: 16px;
}

.product-grid-item .price {
    display: block;
    color: var(--wd-primary-color);
}

.price del {
    color: #bbb;
    padding: 0px 10px 0px;
}

.price .amount {
    font-size: 16px;
}

del .amount {
    color: var(--color-gray-300);
    font-weight: 400;
}

.amount {
    color: var(--wd-primary-color);
    font-weight: 600;
}

bdi {
    unicode-bidi: isolate;
}

.price ins {
    padding: 0;
    background-color: transparent;
    color: #83b735;
    text-decoration: none;
    opacity: 1;
}

.add-to-cart {
    display: flex;
    align-items: center;
    justify-content: center;
}

.add-to-cart .cart-button {
    background: #2d2c2c;
    display: flex;
    gap: 12px;
    align-items: center;
    justify-content: center;
    padding: 10px 14px;
    color: white;
    border: none;
    outline: none;
    border-radius: 2px;
    max-width: '100%';
    font-family: sans-serif;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 500;
    box-shadow: inset 0 -2px 0 rgba(0, 0, 0, .15);
}

.wd-entities-title {
    display: block;
    font-size: 14px;
    padding: 0 14px;
    color: var(--wd-entities-title-color);
    word-wrap: break-word;
    font-weight: var(--wd-entities-title-font-weight);
    font-style: var(--wd-entities-title-font-style);
    font-family: var(--wd-entities-title-font);
    text-transform: var(--wd-entities-title-transform);
    line-height: 1.4;
    min-height: 55px;
}

.wd-entities-title a {
    text-decoration: none;
    box-shadow: none;
    color: #333333;
    transition: all .25s ease;
}

.wd-entities-title a:hover {
    color: #333333;
    opacity: 0.8;
}

.product-id-detail {
    display: flex;
    padding-top: 16px;
    padding-bottom: 16px;
    margin-top: 182px;
}

.quick-view .quick-imgs {
    margin-top: 20px;
}

.quick-view .quick-imgs .img-box {
    border-radius: 4px;
    object-fit: contain;
    padding: 0 4px;
}

.quick-view .quick-imgs .img-box[class*='selected'] {
    border: 1px solid rgb(159, 32, 137);
}

.left-body {
    background: white;
    margin: 0 20px;
    width: 100%;
    border-radius: 8px;
}

.left-body .image-container {
    border: 1px solid rgb(234, 234, 242);
    height: 472px;
    border-radius: 4px;
    position: relative;
}

.left-body .image-container .view-box {
    position: absolute;
    height: 100%;
    width: 100%;
    padding: 20px;
    object-fit: contain;
    color: transparent;
}

.left-body .action-bars {
    background: white;
    border-radius: 8px;
    margin-top: 20px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}

.left-body .action-bars .btn {
    padding: 12px 0;
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.left-body .action-bars .btn button {
    flex: 1 1 0%;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-family: monospace;
    cursor: pointer;
    padding: 12px;
    border: 1px solid rgb(234, 234, 242);
    text-align: center;
    border-radius: 8px;
    background: #2d2c2c;
}

.left-body .action-bars .btn button[class*='invert'] {
    font-size: 22px;
    border: 1px solid rgb(45, 44, 44);
    background: white;
}

.left-body .action-bars .similar-prod {
    color: #353543;
    font-size: x-large;
    font-weight: 800
}

.right-body .content-box {
    padding: 20px 16px;
    margin-bottom: 20px;
    border: 1px solid rgb(234, 234, 242);
    background: white;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    overflow-wrap: break-word;
}

.right-body .content-box .title {
    color: #8b8ba3;
    font-size: 22px;
    font-weight: bold;
}

.right-body .content-box .title[class*='mb-4'] {
    color: black;
    margin-bottom: 16px;
}

.content-box .purch-rate {
    font-size: 32px;
    font-weight: 500;
    font-family: sans-serif;
    color: black;
    width: 100%
}

.content-box .purch-rate .tax {
    font-size: '16px';
    font-weight: 500;
    font-family: 'sans-serif';
    color: 'black'
}

.content-box .qty-inpt {
    color: white;
    border-radius: 3px;
    font-size: 30px;
    padding: 8px 18px;
    cursor: pointer;
}

.CartLayout {
    display: flex;
    -webkit-box-pack: justify;
    /*justify-content: space-between;
    */
    -webkit-box-align: center;
    align-items: center;
    background-color: rgb(255, 255, 255);
    border-radius: 8px;
    padding-top: 12px;
}

.CartLayout .RatingBg {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    font-size: 20px;
    color: white;
    padding: 6px 12px;
    background: rgb(35, 187, 117);
    fill: white;
}

.CartLayout .RatingBg .RatingValue {
    color: rgb(255, 255, 255);
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    font-family: "Mier demi";
    margin: 0px;
    padding: 0px;
}

.CartLayout .Reviews {
    font-size: 20px;
    color: rgb(139, 139, 163);
    font-weight: 600;
    display: flex;
    align-items: end;
    margin-left: 16px;
}

.out-of-stock {
    padding-top: 16px;
    background-color: white;
    font-size: 18px;
    color: maroon;
    opacity: 0.8;
    margin-left: 12px
}

.content-box .size-container {
    font-size: 22px;
    color: black;
    border: 1px solid #353543;
    width: fit-content;
    padding: 4px 28px;
    border-radius: 40px;
    margin: 6px 12px 6px 0px;
}

.content-box .prod-det {
    display: inline;
    color: #616173;
    font-size: large;
}

@media screen and (max-width: 430px) {

    .categories .Products .owl-carousel .owl-stage-outer {
        z-index: -1;
    }

    .categories .Products .owl-carousel .owl-nav {
        position: absolute;
        z-index: -1;
        top: calc(50% - 23px);
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    .categories .Products .owl-carousel .owl-nav button {
        width: 40px;
        height: 50px;
        background: #2d2c2c;
        color: #fff;
        font-size: 36px;
        margin: 0;
        z-index: 555;
        line-height: 25px;
        padding-bottom: 5px !important;
        text-align: center;
        border-radius: 5px;
    }

    .categories .Products .owl-carousel .owl-nav button span {
        height: 36px;
    }
}