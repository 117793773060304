@font-face {
    font-family: "ProximaNovaRegular";
    src: url("../../public/assests/fonts/ProximaNova-Regular.otf");
}

@font-face {
    font-family: "ProximaNovaMedium";
    src: url("../../public/assests/fonts/ProximaNova-Medium.otf");
}

@font-face {
    font-family: "ProximaNovaBold";
    src: url("../../public/assests/fonts/ProximaNova-Bold.otf");
}

@font-face {
    font-family: "ProximaNovaSemiBold";
    src: url("../../public/assests/fonts/ProximaNova-Semibold.otf");
}

@font-face {
    font-family: "ProximaNovaExtraBold";
    src: url("../../public/assests/fonts/ProximaNova-Extrabld.otf");
}


@font-face {
    font-family: "ProximaNovaThin";
    src: url("../../public/assests/fonts/ProximaNova-Thin.otf");
}

.heavitas {
    font-family: "Heavitas";

}

.sporfy-game {
    font-family: "SporfyGames";
    font-size: 47px !important;
}

.proxima-nova-regular {
    font-family: "ProximaNovaRegular";
}

.proxima-nova-medium {
    font-family: "ProximaNovaMedium";
}

.proximaNova-regular,
.proximaNova-regular {
    font-family: "ProximaNovaRegular" !important;
}

.proxima-nova-medium,
.proximaNova-medium,
.proximaNova-Medium {
    font-family: "ProximaNovaMedium" !important;
}

.proximaNova-semibold,
.proximaNova-Semibold,
.proximaNova-SemiBold,
.proxima-nova-semibold {
    font-family: "ProximaNovaSemibold" !important;
}

.proxima-nova-bold,
.proximaNova-bold {
    font-family: "ProximaNovaBold" !important;
}

.proxima-nova-extraBold {
    font-family: "ProximaNovaExtraBold" !important;
}

.proxima-nova-light,
.proximaNova-light {
    font-family: "ProximaNovaLight" !important;
}

.proxima-nova-thin,
.proximaNova-thin {
    font-family: "ProximaNovaThin" !important;
}

.roboto-bold {
    font-family: "Roboto-Bold" !important;
}