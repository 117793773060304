.outletform{
    padding-left: 3%;
    font-weight: bold;
     
    
}
.outletfield{
    width: 45%;
    position: relative;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}
.labelname{
    margin-top: 1%;
    color:#19394b ;
    font-family:Verdana;
    font-weight: 600;
  font-size: 14px;
}

.outletform .outlettextfield{
    opacity: 0.8;
    margin-bottom: 1%;
    border-radius: 8px;
    position: relative;
    width: 40ch;
   
}
.outletform .formdate{
    width: 20ch;
    margin-bottom: 0.5%;
   
}

.outlethead{
    color:#ff1515 ;  
    text-align:left;
    padding-left: 15px;
  font-size: 25px;
    padding: 1%;
   font-weight:bolder ;
   border-bottom: 1px solid #424242;  
   font-family:Verdana;
  text-shadow: #424242;
   position: relative;
}
.outletform .fromsave{
    margin-left: 4%;
    width: 18%;
    font-weight: 600;
}

.outletform .fromview{
    margin-left: 55%;
    width: 18%;
    font-weight: 600;
    background-color: aliceblue;
    border: 1px solid red;
    color: red;
}

.outletform .fromview:hover{
    background-color: aliceblue;
}

.outletform .labelstate{
   margin-left: 42%;
    position: relative;
    display: flex;
    margin-bottom: 0%;
    margin-top: -9.5%;
    color:#19394b ;
    font-family:Verdana;
    font-size: 14px;
}

.outletform .labelpin{
    margin-left: 42%;
    position: relative;
    display: flex;
    margin-bottom: 0%;
    margin-top: -9.5%;
    color:#19394b ;
    font-family:Verdana;
    font-size: 14px;  
}

.outletform .outlettextfieldstate{
    margin-left: 42%;
    position: relative;
    margin-bottom: 2%;
    
}

.outletform .outlettextfieldpin{
    margin-left: 42%;
    position: relative;
}

.outletdiv{
    align-items: center;
    display: flex;
    justify-content: center;
    margin-top: 2%;
    position: relative;
}

/*------------------------max-width 320px-------------------------------*/

@media only screen and (max-width:320px){
    .outletfield{
       
        border-radius: 8px;
    }
    .formhead{
        color:#ff1515 ;  
        text-align:left;
        padding-left: 15px;
      font-size: 15px;
        padding: 1%;
       font-weight:bolder ;
       border-bottom: 1px solid #424242;  
       font-family:Verdana;
      text-shadow: #424242;
      
    }
    .outletfield{
       width: 80%;
         
    }
    .outletform .outlettextfield{
        opacity: 0.7;
        margin-bottom: 1%;
        width: 30ch;
   
    }
    .outletform .labelstate{
        margin-left: 0%;
        display: block;
        margin-top: 0%;
       
    }
    .outletform .labelpin{
        margin-left: 0%;
        display: block;
        margin-top: 0%;
    }
    .outletform .outlettextfieldstate{
        margin-left: 0%;
        position: relative;
       
        
    }
    
    .outletform .outlettextfieldpin{
        margin-left: 0%;
        position: relative;
    }
    .outletform .fromview{
        margin-left: 45%;
        width: 18%;
        font-weight: 600;
        background-color: aliceblue;
        border: 1px solid red;
        color: red;
    }
    
    .outletform .fromview:hover{
        background-color: aliceblue;
    }

}

/*---------------------------max-width 1024px--------------------------*/

@media only screen and (max-width:1024px) {
    .outletfield{
        width: 55%;
        border-radius: 8px;
    }
    .formhead{
        color:#ff1515 ;  
        text-align:left;
        padding-left: 15px;
      font-size: 15px;
        padding: 1%;
       font-weight:bolder ;
       border-bottom: 1px solid #424242;  
       font-family:Verdana;
      text-shadow: #424242;
      
    }
    .outletfield{
       width: 90%;
         
    }
    .outletform .outlettextfield{
        opacity: 0.7;
        margin-bottom: 1%;
        width: 35ch;
   
    }
    .outletform .labelstate{
        margin-left: 0%;
        display: block;
        margin-top: 0%;
       
    }
    .outletform .labelpin{
        margin-left: 0%;
        display: block;
        margin-top: 0%;
    }
    .outletform .outlettextfieldstate{
        margin-left: 0%;
        position: relative;
       
        
    }
    
    .outletform .outlettextfieldpin{
        margin-left: 0%;
        position: relative;
    }
}
