.templateContainer0 {
  position: relative;
  width: 100%;
  padding: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
}

.itemCheckbox {
  width: 20px;
  height: 20px;
}
.templateContainer {
  position: relative;
  width: 100%;
  box-shadow: 0 7px 25px rgba(0, 0, 0, 0.8);
  border-radius: 20px;

  overflow: scroll;

}

.templateContainer>.searchInput_Container input {
  width: 90%;
  padding: 10px;
  outline: none;
  border-radius: 10px;
  position: relative;
  margin-left: 40px;
  margin-top: 5px;
}

.templateContainer .template_Container .template {
  background-color: white;
  align-items: center;
  text-align: center;
  justify-content: center;
  width: 100%;
  height: 74vh;
  padding: 0px 20px;
  display: grid;
  border-radius: 10px;

}

.templateContainer .template_Container .template img {
  height: 275px;
  padding-left: 150px;
  margin-bottom: -53px;
  margin-top: -40px;
}

.templateContainer .template_Container .template .title {
  font-size: 20px;
  color: rgb(255, 47, 47);
  height: 20px
}

.templateContainer .template_Container .template .pricee {
  font-size: 20px;
  font-weight: bolder;
  color: rgb(5, 5, 5);
  height: 20px
}

.templateContainer .template_Container .template .qty {
  font-size: 20px;
  font-weight: bolder;
  color: rgb(46, 136, 24);
  height: 20px
}

.templateContainer .template_Container .template .price {
  font-size: 20px;
  font-weight: bolder;
  color: blue;
}


.templateContainer .template_Container .template .des {
  font-size: 20px;
  font-weight: bolder;
  color: rgb(20, 25, 29);
}

.templateContainer .template_Container .template button {
  background-color: rgb(255, 0, 0);
  position: relative;
  border-radius: 10px;
  height: 30px;
  margin: 5px;
  border: 1px solid black;
}

.templateContainer .template_Container .template button:hover {
  height: 35px;
  width: 100%;
  cursor: pointer;
  background-color: blue;
}

.templateContainer .template_Container .template button.buy {
  font-size: 25px;
  color: rgb(255, 255, 255);
  margin-bottom: 10px;
}

@media (max-width :991px) {
  .templateContainer>.searchInput_Container input {
    width: 80%;
    padding: 10px;
    outline: none;
    border-radius: 10px;
    position: relative;
    margin-left: 40px;
    margin-top: 5px;
  }

  .templateContainer0 {
    display: inline;
    position: relative;
  }

  .templateContainer .template_Container .template {
    height: 65vh;
  }

  .templateContainer {
    position: relative;
    border-radius: 20px;
  }

  .templateContainer .template_Container .template img {
    height: 200px;
    position: relative;
    padding-left: 70px;
    justify-content: center;
    align-items: left;
  }

  .templateContainer .template_Container .template .des {
    font-size: 18px;
    color: rgb(20, 25, 29);
    align-items: center;
  }

  .templateContainer .template_Container .template button {
    background-color: rgb(255, 0, 0);
    position: relative;
    width: 100%;
    border-radius: 10px;
  }

  .templateContainer .template_Container .template button:hover {
    width: 100%;

  }

  .templateContainer1 {
    margin-top: 15px;
    width: auto;
  }

  .buynowbtn {
    margin-top: 95%;
  }

  .productname {
    font-size: 20px;
    margin-top: 15px;
    text-align: center;
  }
}

.templateContainer1 {
  position: relative;
  width: 100%;
  box-shadow: 0 7px 25px rgba(0, 0, 0, 0.8);
  border-radius: 20px;
  height: 70vh;
}

.pos2title {
  font-size: 20px;
  text-align: center;
  align-items: center;
  color: rgb(126, 118, 118);
}

.productname {
  font-size: 18px;
  margin-top: 10px;
  padding-left: 50px;
  background-color: rgb(126, 118, 118);
  color: white;
}

.quandity {
  font-size: 18px;
  padding-left: 15px;
  margin-top: 15px;
  padding-left: 100px;
  background-color: rgb(126, 118, 118);
  color: white;
}

.prices {
  font-size: 18px;
  padding-left: 15px;
  margin-top: 15px;
  margin-bottom: 15px;
  padding-left: 100px;
  background-color: rgb(126, 118, 118);
  color: white;
}

.values {
  font-size: 18px;
  padding-left: 15px;
  margin-top: 15px;
  margin-bottom: 15px;
  padding-left: 150px;
  background-color: rgb(126, 118, 118);
  color: white;
}

.buynowbtn {
  color: rgb(255, 255, 255);
  background-color: rgb(0, 140, 255);
  position: relative;
  font-size: 18px;
  border-radius: 8px;
  width: 20%;
  margin-top: 35%;
  margin-left: 350px;
  height: 40px;
  border: none;
  cursor: pointer;
  margin-bottom: 10px;
}

.buynowbtn:hover {
  background-color: rgb(0, 126, 63);
}

.cancelbtn {
  cursor: pointer;
  color: rgb(255, 255, 255);
  background-color: rgb(209, 9, 235);
  position: relative;
  font-size: 18px;
  border-radius: 8px;
  width: 20%;
  margin-top: 10px;
  margin-left: 5px;
  border: none;
  height: 40px;
  margin-bottom: 10px;
  justify-content: center;
}

.cancelbtn:hover {
  background-color: rgb(0, 0, 0);
}

.check-offers {
  color: black;
  position: absolute;
  top: -30px;
  white-space: nowrap;
  font-size: 14px;
  font-weight: 600;
  left: 0;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-center-between {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
